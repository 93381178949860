import * as React from "react";
import { useState } from "react";
import AppBar from "@mui/material/AppBar";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MobileLogo from "../../../Assets/MobileLogo.svg";
import MailIcon from "@mui/icons-material/Mail";
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Badge from "@mui/material/Badge";
import MobileNavDrawer from "../../../Components/Drawers/MobileDrawer";
import NotificationDrawer from "../../../Components/Drawers/NotificationDrawer";

export default function MobileNavAppBar() {
	const [open, setOpen] = useState(false);
	const [openNoti, setOpenNoti] = useState(false);
	return (
		<AppBar className="mobileClassNav-appbar" position="static">
			<MobileNavDrawer open={open} setOpen={setOpen} />
			<NotificationDrawer open={openNoti} setOpen={setOpenNoti} />
			<Toolbar className="justify-between">
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					className="mr-[2px]"
					onClick={() => setOpen(!open)}>
					<MenuIcon className="text-[#105998]" />
				</IconButton>
				<img className="h-[30px] w-[30px]" src={MobileLogo} alt="img" />
				<IconButton
					size="large"
					edge="start"
					color="inherit"
					aria-label="menu"
					className="mr-[2px]"
					onClick={() => setOpenNoti(!openNoti)}>
					<NotificationsNoneOutlinedIcon className="text-[#105998]" />
				</IconButton>
			</Toolbar>
		</AppBar>
	);
}
