import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SidePanels.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";

export default function SiteDrawer(props) {
	const { open, setOpen, site } = props;

	const list = (name, country, installDate, solution, sector) => (
		<Box
			className="NotificationDrawer w-[300px] p-[10px]"
			role="presentation">
			<div className="marker-layout">
				<div className="HeadingMarker">
					<div className="HeadingAddress">
						<Headings className="text-base">{name}</Headings>
						<IconButton
							aria-label="delete"
							color="primary"
							onClick={() => setOpen(false)}>
							<CloseIcon />
						</IconButton>
					</div>
					<SubHeadings className="text-xs text-[#aeb3bc]">
						{country}
					</SubHeadings>
				</div>
				<Divider />
				<div className="BottomSection">
					<div className="fx-space">
						<SubHeadings className="text-xs text-[#aeb3bc]">
							Install Date
						</SubHeadings>
						<SubHeadings className="text-xs text-black">
							{site?.installDate}
						</SubHeadings>
					</div>
					<div className="fx-space">
						<SubHeadings className="text-xs text-[#aeb3bc]">
							Solution
						</SubHeadings>
						<SubHeadings className="text-xs text-black">
							{solution}
						</SubHeadings>
					</div>

					<div className="fx-space">
						<SubHeadings className="text-xs text-[#aeb3bc]">
							Sector
						</SubHeadings>
						<SubHeadings className="text-xs text-black">
							{sector}
						</SubHeadings>
					</div>
				</div>
			</div>
		</Box>
	);

	return (
		<Drawer
			anchor="right"
			open={open}
			hideBackdrop={true}
			className="w-[300px] h-full z-50 p-5">
			{list(
				"Test Site",
				"England",
				"11-21-2005",
				"fire Door",
				"Residential"
			)}
		</Drawer>
	);
}
