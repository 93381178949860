import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { TextField, Button, Grid, MenuItem, IconButton, CircularProgress } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useDispatch, useSelector } from "react-redux";
import { getDoor, updateDoor, addDoor } from "../../Store/Features/Door";
import { getSites } from "../../Store/Features/Sites";
import { getFloorsOfSite, getFloor } from "../../Store/Features/Floors";
import { getUsers } from "../../Store/Features/Users";
import { getOrganization } from "../../Store/Features/Customer";
import { ImageUploadButton } from "./ImageUploadButton";

const AddDoorPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const isEdit = !!slug;

  const { loading } = useSelector((state) => state.doors);
  const sites = useSelector((state) => state.sites.value);
  const { 
    loading: loadingUsers, 
    value: usersValue, 
    error: usersError 
  } = useSelector((state) => state.users);
  const users = usersValue?.data || [];
  const totalItems = usersValue?.totalItems || 0;
  const { user } = useSelector((state) => state.auth);

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [installationManagers, setInstallationManagers] = useState([]);
  const [site, setSite] = useState(null);
  const [floors, setFloors] = useState([]);
  const [orgName, setOrgName] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const fetchInitialData = useCallback(async () => {
    setIsLoading(true);
    try {
      await Promise.all([
          dispatch(getSites({
            page: 1,
            limit: 100,  // Adjust as needed
            search: '',
            sort: 'desc',
            field: 'createdAt'
          })),
        dispatch(getUsers({
          page: 1,
          limit: 100, 
          search: '',
          sort: 'desc',
          field: 'createdAt',
          roleId: process.env.REACT_APP_AGENT_ROLE_ID
        }))
      ]);
     

      if (isEdit) {
        const doorResponse = await dispatch(getDoor(slug));
        if (!doorResponse.error) {
          const doorData = doorResponse.payload;
          setFormData(doorData);
          if (doorData.floor?.id) {
            const floorResponse = await dispatch(getFloor(doorData.floor.id + "?for_door=1"));
            if (!floorResponse.error) {
              setSite(floorResponse.payload?.site?.id);
              setFormData(prev => ({ 
                ...prev, 
                floorId: doorData.floor.id,
                assignee: doorData.assignee
              }));
              if (floorResponse.payload?.site?.organizationId) {
                const orgResponse = await dispatch(getOrganization(floorResponse.payload.site.organizationId));
                setOrgName(orgResponse.payload?.name);
              }
            }
          }
        }
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isEdit, slug, dispatch]);

  useEffect(() => {
    fetchInitialData();
  }, [fetchInitialData]);

  useEffect(() => {
    if (users?.length > 0) {
      const managers = users.filter(ele => ele.roleId === process.env.REACT_APP_AGENT_ROLE_ID);
      setInstallationManagers(managers);
    }
  }, [users]);

  useEffect(() => {
    if (site) {
      dispatch(getFloorsOfSite(site + "&for_door=1")).then((res) => {
        if (res.payload && res.payload.data) {
          setFloors(res.payload.data);
        }
      });
    }
  }, [site, dispatch]);

  useEffect(() => {
    if (isEdit && sites && sites.length > 0 && formData.floor) {
      const siteOfFloor = sites.find(s => s.id === formData.floor.siteId);
      if (siteOfFloor) {
        setSite(siteOfFloor.id);
      }
    }
  }, [isEdit, sites, formData.floor]);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);
    setFormData(prevData => {
      const newData = { ...prevData, [name]: value };
      console.log('New formData:', newData);
      return newData;
    });
  }, []);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const action = isEdit ? updateDoor({ id: slug, data: formData }) : addDoor({ data: formData });
    const response = await dispatch(action);
    if (!response.error) {
      navigate("/doors");
    }
  };

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    const regexPattern = /hf\d{7}/i;
    if (!formData.description) {
      newErrors.description = "Description is required";
      valid = false;
    } else if (!formData.description.trim().match(regexPattern)) {
      newErrors.description = "Please Enter Valid Harmony Code for Door, e.g. HFXXXXXXX";
      valid = false;
    }

    if (!formData.floorId) {
      newErrors.floor = "Floor is required";
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const renderTextField = (name, label, disabled = false) => (
    <Grid item xs={12} sm={12} md={6} lg={6}>
      <TextField
        label={label}
        variant="filled"
        fullWidth
        name={name}
        value={formData[name] || ""}
        onChange={handleChange}
        InputLabelProps={{ shrink: true }}
        error={!!errors[name]}
        helperText={errors[name]}
        disabled={disabled}
      />
    </Grid>
  );

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div className="OverAllSites">
      <div style={{ display: "flex", alignItems: "center", gap: 15 }}>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardBackspaceIcon />
        </IconButton>
        <Headings fontSize={24}>{isEdit ? "Edit Door" : "Add Door"}</Headings>
      </div>
      <div className="Addsitetabs">
        <form onSubmit={handleFormSubmit}>
          <Grid container spacing={2}>
            {renderTextField("description", "Harmony Code")}
            {renderTextField("width", "Width")}
            {renderTextField("height", "Height")}
            {renderTextField("door_type", "Door Type")}
            {renderTextField("door_no", "Door Number")}
            {renderTextField("top_left_measurement", "Top Left Gap")}
            {renderTextField("top_right_measurement", "Top Right Gap")}
            {renderTextField("bottom_left_measurement", "Bottom Left Gap")}
            {renderTextField("bottom_right_measurement", "Bottom Right Gap")}
            {renderTextField("hinge_top_measurement", "Hinge Top Gap")}
            {renderTextField("hinge_bottom_measurement", "Hinge Bottom Gap")}
            {renderTextField("swing_top_measurement", "Swing Top Gap")}
            {renderTextField("swing_bottom_measurement", "Swing Bottom Gap")}
            {renderTextField("top_left_offset", "Top Left Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("top_right_offset", "Top Right Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("bot_left_offset", "Bottom Left Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("bot_right_offset", "Bottom Right Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("hinge_top_offset", "Hinge Top Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("hinge_bot_offset", "Hinge Bottom Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("swing_top_offset", "Swing Top Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}
            {renderTextField("swing_bot_offset", "Swing Bottom Offset", user?.roleId !== process.env.REACT_APP_ADMIN_ROLE_ID)}

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Direction"
                variant="filled"
                fullWidth
                name="direction"
                value={formData.direction || ""}
                onChange={handleChange}
                error={!!errors.direction}
                helperText={errors.direction}
                InputLabelProps={{ shrink: true }}
              >
                <MenuItem value="CW">Clock Wise</MenuItem>
                <MenuItem value="CCW">Counter Clock Wise</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Assign To"
                variant="filled"
                fullWidth
                name="assignee"
                value={formData.assigneeId || ""}
                onChange={handleChange}
                error={!!errors.assigneeId}
                helperText={errors.assigneeId}
                InputLabelProps={{ shrink: true }}
              >
                {installationManagers?.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.firstName + " " + (option.lastName || "")}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Site"
                variant="filled"
                fullWidth
                name="site"
                value={site || ""}
                onChange={(e) => {
                  setSite(e.target.value);
                  setFormData(prev => ({ ...prev, floorId: "" }));
                }}
                error={!!errors.site}
                InputLabelProps={{ shrink: true }}
                helperText={orgName ? `Site of organization ${orgName}` : ""}
              >
                {sites?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} sm={12} md={6} lg={6}>
              <TextField
                select
                label="Floor"
                variant="filled"
                fullWidth
                sx={{ display: site ? null : "none" }}
                name="floorId"
                value={formData.floorId || ""}
                onChange={handleChange}
                InputLabelProps={{ shrink: true }}
                error={!!errors.floor}
                helperText={errors.floor}
              >
                {floors?.map((option) => (
                  <MenuItem key={option.data.id} value={option.data.id}>
                    {option.data.description}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <ImageUploadButton
                onChange={(name, value) => setFormData(prev => ({ ...prev, [name]: value }))}
                name="avatar"
                path="doors/door_image"
                form={formData}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                className="customButtomOverAll"
                disabled={loading}
                type="submit"
                variant="contained"
                color="primary"
              >
                {loading ? <CircularProgress color="inherit" /> : (isEdit ? "Update" : "Create")}
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </div>
  );
};

export default AddDoorPage;