import React from "react";
import "./Door.css";
import DoorBackOutlinedIcon from "@mui/icons-material/DoorBackOutlined";
import DoorFrontOutlinedIcon from "@mui/icons-material/DoorFrontOutlined";
import { Headings } from "../../Shared/Fonts/Fonts";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import { Divider } from "@mui/material";

export const DoorCardUtil = (props) => {
	const { title, time } = props;

	return (
		<div className="doorCardUtils">
			<div className="doorCardUpper">
				<Headings className="text-white text-base font-medium">
					{title || "N/A"}
				</Headings>
			</div>
			<Divider className="w-full bg-white" />
			<div className="doorCardDown">
				<Headings className="text-white text-sm font-medium font-futura-book">
					{time || "N/A"}
				</Headings>
			</div>
		</div>
	);
};
