import React from "react";
import { SubHeadings } from "../Fonts/Fonts";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { getStatusMessages } from "../../Utils/Functions/getStatusMessages";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import "./Custom.css";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate, useParams } from "react-router-dom";

const CustomTooltip = ({ data, position, floorNumber, setShowPopUp }) => {
	const navigate = useNavigate();
	const { siteId, floorId } = useParams();
	const matches = useMediaQuery("(max-width:769px)");
	const Status = getStatus(data.status).Component;
	console.log("data", data);
	return (
		<div className="custom-tooltip" style={position}>
			<svg
				className="absolute -bottom-[13px] left-[42.5%]"
				xmlns="http://www.w3.org/2000/svg"
				width="27"
				height="18"
				viewBox="0 0 27 18"
				fill="none">
				<g filter="url(#filter0_d_507_901)">
					<path
						d="M20.2758 2.25769L11.2461 11.2874L2.21643 2.25769H20.2758Z"
						fill="white"
					/>
					<path
						d="M20.2758 2.25769L11.2461 11.2874L2.21643 2.25769H20.2758Z"
						fill={
							data.status
								? getStatus(data.status).color
								: "#34A853"
						}
						fill-opacity="0.1"
					/>
				</g>
				<defs>
					<filter
						id="filter0_d_507_901"
						x="0.216309"
						y="0.257812"
						width="26.0596"
						height="17.0295"
						filterUnits="userSpaceOnUse"
						color-interpolation-filters="sRGB">
						<feFlood
							flood-opacity="0"
							result="BackgroundImageFix"
						/>
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset dx="2" dy="2" />
						<feGaussianBlur stdDeviation="2" />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
						/>
						<feBlend
							mode="normal"
							in2="BackgroundImageFix"
							result="effect1_dropShadow_507_901"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect1_dropShadow_507_901"
							result="shape"
						/>
					</filter>
				</defs>
			</svg>
			<div
				className="w-full justify-between"
				style={{
					display: matches ? "flex" : "none",
				}}>
				{matches && (
					<>
						<button
							className="addButton"
							onClick={() =>
								navigate(
									`/sites/${siteId}/floor/${floorId}/asset/${data.data.id}`
								)
							}>
							View Door
						</button>
						<IconButton onClick={() => setShowPopUp(false)}>
							<CloseIcon />
						</IconButton>
					</>
				)}
			</div>

			<div className="flex-between">
				<SubHeadings
					className="text-base"
					color={
						data.status ? getStatus(data.status).color : "#FFA500"
					}>
					{data?.data?.description || data.description}
				</SubHeadings>
				<Status />
			</div>
			<div className="flex-between gap-[10px]">
				<SubHeadings className="text-black text-base">
					Floor {floorNumber}
				</SubHeadings>
				<SubHeadings className="text-black text-base">
					{getStatusMessages(data.status).split("-")[1]}
				</SubHeadings>
			</div>
			{/* Add more fields as needed */}
		</div>
	);
};

export default CustomTooltip;

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		const Component = () => {
			return (
				<CheckCircleOutlineOutlinedIcon className="w-4 h-4 text-[#34A853]" />
			);
		};
		return {
			Component,
			color: "#34A853",
		};
	} else if (status >= 300 && status < 400) {
		const Component = () => {
			return <WarningAmberIcon className="text-[#ddad01] w-4 h-4" />;
		};
		return {
			Component,
			color: "rgba(221, 173, 1, 1)",
		};
	} else if (status >= 400 && status < 500) {
		const Component = () => {
			return <WarningAmberIcon className="text-[#eb4435] w-4 h-4" />;
		};
		return {
			Component,
			color: "red",
		};
	} else {
		const Component = () => {
			return (
				<CheckCircleOutlineOutlinedIcon className="w-4 h-4 text-[#DD6E0F]" />
			);
		};
		return {
			Component,
			color: "#DD6E0F",
		};
	}
};
