import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Select from "../../Shared/CustomElement/DropDown";
import { getFloorsOfSite, deleteFloor } from "../../Store/Features/Floors";
import { useDispatch, useSelector } from "react-redux";
import FloorHoverComponent from "../../Shared/CustomElement/FloorTootlTip";
import AssetDrawer from "../../Components/Drawers/AssetDrawer";
import { getSites, getSitesOfOrganization } from "../../Store/Features/Sites";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableEdgeDrawer from "../../Components/Drawers/Swipeable";

import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

import "./Floor.css";

function FloorMap() {
	const canvasRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { siteId } = useParams();
	const { value } = useSelector((state) => state.sites);
	const { user } = useSelector((state) => state.auth);
	const matches = useMediaQuery("(max-width:769px)");
	const [data, setData] = useState();
	const [open, setOpen] = useState(false);
	const [floorId, setFloorID] = useState(null);
	const [Floors, setFloors] = useState([]);
	const [hoveredFloorId, setHoveredFloorId] = useState(null);
	const [hoverPosition, setHoverPosition] = useState({ x: 0, y: 0 });
	const [isEditMode, setIsEditMode] = useState(false);
	const ref = useRef(null);
	// useEffect(() => {
	//   if (Floors?.length > 0) {
	//     let scene, camera, renderer, controls;
	//     let hoveredFloorId = null;
	//     const floorMeshes = [];
	//     const floorEdgesMeshes = [];

	//     const init = () => {
	//       scene = new THREE.Scene();

	//       // Add ambient light to illuminate the scene
	//       const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
	//       scene.add(ambientLight);

	//       camera = new THREE.PerspectiveCamera(
	//         matches ? 100 : 70,
	//         window.innerWidth / 750,
	//         0.2,
	//         1000
	//       );
	//       camera.position.set(3, 3, 8);
	//       // camera.position.set(-8, 0, 8);
	//       camera.lookAt(scene.position);

	//       renderer = new THREE.WebGLRenderer({
	//         alpha: true,
	//         canvas: canvasRef.current,
	//       });
	//       renderer.setSize(window.innerWidth, window.innerHeight - 100);

	//       const floorWidth = 4;
	//       const floorHeight = 0.65;
	//       const floorDepth = 3;
	//       const floorRadius = 0.1;
	//       const floorSpacing = 0.3;

	//       for (let i = 0; i < Floors?.length; i++) {
	//         const color = getStatus(Floors[i].status).color;
	//         const edgeColor = getStatus(Floors[i].status).edge;

	//         // i === Floors?.floor_no - 1 ? floorColors[Floors[i]] || floorColors.default : floorColors.default;

	//         const shape = new THREE.Shape();
	//         const roundedRect = (ctx, x, y, width, height, radius) => {
	//           ctx.moveTo(x + radius, y);
	//           ctx.lineTo(x + width - radius, y);
	//           ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
	//           ctx.lineTo(x + width, y + height - radius);
	//           ctx.quadraticCurveTo(
	//             x + width,
	//             y + height,
	//             x + width - radius,
	//             y + height
	//           );
	//           ctx.lineTo(x + radius, y + height);
	//           ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
	//           ctx.lineTo(x, y + radius);
	//           ctx.quadraticCurveTo(x, y, x + radius, y);
	//         };
	//         roundedRect(
	//           shape,
	//           -floorWidth / 2,
	//           -floorHeight / 2,
	//           floorWidth,
	//           floorHeight,
	//           floorRadius
	//         );

	//         const extrudeSettings = { depth: floorDepth, bevelEnabled: true };
	//         const floorGeometry = new THREE.ExtrudeGeometry(
	//           shape,
	//           extrudeSettings
	//         );

	//         const floorMaterial = new THREE.MeshStandardMaterial({
	//           color,
	//           roughness: 0.3,
	//           metalness: 0.3,
	//         });
	//         const floorMesh = new THREE.Mesh(floorGeometry, floorMaterial);

	//         floorMesh.position.y = i * (floorHeight + floorSpacing);

	//         floorMesh.userData = Floors[i];
	//         scene.add(floorMesh);
	//         floorMeshes[i] = floorMesh;

	//         const floorEdgesGeometry = new THREE.EdgesGeometry(floorGeometry);
	//         const floorEdgesMaterial = new THREE.LineBasicMaterial({
	//           color: edgeColor,
	//         });
	//         const floorEdgesMesh = new THREE.LineSegments(
	//           floorEdgesGeometry,
	//           floorEdgesMaterial
	//         );

	//         floorEdgesMesh.position.y = i * (floorHeight + floorSpacing);
	//         floorEdgesMesh.userData = Floors[i];

	//         scene.add(floorEdgesMesh);
	//         floorEdgesMeshes[i] = floorEdgesMesh;
	//       }

	//       const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
	//       directionalLight.position.set(1, 1, 1.8);
	//       scene.add(directionalLight);

	//       controls = new OrbitControls(camera, renderer.domElement);
	//       console.log(controls);
	//       controls.enableRotate = false;
	//       controls.enableZoom = true;
	//       controls.enablePan = true;
	//       controls.minDistance = 1;
	//       controls.maxDistance = 50;
	//       controls.update();

	//       renderer.domElement.addEventListener("mousemove", handleFloorHover);
	//       renderer.domElement.addEventListener("click", handleClick);
	//       window.addEventListener("resize", handleWindowResize);
	//       animate();
	//     };

	//     const handleFloorHover = (event) => {
	//       const intersects = getIntersects(event);
	//       const distance = intersects[0];

	//       if (intersects.length > 0) {
	//         const hoveredFloor = intersects[0].object;

	//         const floorId = hoveredFloor.userData.data.id;
	//         setData(hoveredFloor.userData);

	//         if (floorId !== hoveredFloorId) {
	//           if (hoveredFloorId !== null) {
	//             setOpen(false);
	//             resetFloorSize(hoveredFloorId);
	//             setFloorID(null);
	//           }
	//           const rect = canvasRef.current.getBoundingClientRect();

	//           let hoverPosition = {
	//             x: event.clientX + rect.x,
	//             y: event.clientY,
	//           };
	//           const meshPosition = new THREE.Vector3();
	//           hoveredFloor.getWorldPosition(meshPosition);

	//           const canvasHalfWidth = window.innerWidth / 2;
	//           const canvasHalfHeight = window.innerHeight / 2;

	//           const screenPosition = meshPosition.clone().project(camera);

	//           const centerScreenX =
	//             screenPosition.x * canvasHalfWidth + canvasHalfWidth;
	//           const centerScreenY =
	//             -(screenPosition.y * canvasHalfHeight) + canvasHalfHeight;

	//           hoverPosition = {
	//             x: centerScreenX - 40,
	//             y: centerScreenY,
	//           };

	//           setHoveredFloorId(floorId);
	//           setOpen(true);
	//           setFloorID(floorId);
	//           hoveredFloorId = floorId;
	//           increaseFloorSize(hoveredFloorId);
	//           setHoveredFloorId(floorId);
	//           setHoverPosition(hoverPosition);
	//         }
	//       } else if (hoveredFloorId !== null) {
	//         resetFloorSize(hoveredFloorId);
	//         setOpen(false);
	//         hoveredFloorId = null;
	//         setHoveredFloorId(null);
	//       }
	//     };

	//     const increaseFloorSize = (floorId) => {
	//       const floorMesh = floorMeshes.find(
	//         (element) => element.userData.data.id == floorId
	//       );

	//       const floorEdgesMesh = floorEdgesMeshes.find(
	//         (element) => element.userData.data.id == floorId
	//       );

	//       floorMesh.scale.set(1.06, 1.06, 1.06);
	//       floorEdgesMesh.scale.set(1.06, 1.06, 1.06);
	//     };

	//     const resetFloorSize = (floorId) => {
	//       const floorMesh = floorMeshes.find(
	//         (element) => element.userData.data.id == floorId
	//       );

	//       const floorEdgesMesh = floorEdgesMeshes.find(
	//         (element) => element.userData.data.id == floorId
	//       );
	//       if (floorMesh) {
	//         floorMesh.scale.set(1, 1, 1);
	//         floorEdgesMesh.scale.set(1, 1, 1);
	//       }
	//     };

	//     const getIntersects = (event) => {
	//       const mouse = new THREE.Vector2();
	//       const raycaster = new THREE.Raycaster();
	//       const rect = renderer.domElement.getBoundingClientRect();
	//       mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
	//       mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
	//       raycaster.setFromCamera(mouse, camera);
	//       return raycaster.intersectObjects(scene.children, true);
	//     };

	//     const handleClick = (event) => {
	//       const intersects = getIntersects(event);

	//       if (intersects.length > 0) {
	//         const clickedFloor = intersects[0].object;
	//         const floorId = clickedFloor.userData.data.id;

	//         if (matches) {
	//           setHoveredFloorId(floorId);
	//           setData(clickedFloor.userData);
	//         } else {
	//           navigate(`/sites/${siteId}/floor/${floorId}`);
	//         }
	//       }
	//     };

	//     const handleWindowResize = () => {
	//       camera.aspect = window.innerWidth / window.innerHeight;
	//       camera.updateProjectionMatrix();
	//       renderer.setSize(window.innerWidth, window.innerHeight);
	//     };

	//     const animate = () => {
	//       requestAnimationFrame(animate);
	//       controls.update();
	//       renderer.render(scene, camera);
	//     };

	//     init();

	//     return () => {
	//       window.removeEventListener("resize", handleWindowResize);
	//       renderer.domElement.addEventListener("click", handleClick);
	//       renderer.domElement.removeEventListener("mousemove", handleFloorHover);
	//       renderer.dispose();
	//     };
	//   }
	// }, [navigate, Floors]);

	useEffect(() => {
		document.title = "Floors";
	}, []);

	useEffect(() => {
		if (siteId) {
			const data_reducer = "&for_site=1";
			dispatch(getFloorsOfSite(siteId + data_reducer)).then((res) => {
				let result = res.payload.data;

				result.sort(
					(a, b) =>
						parseInt(a?.data?.floor_no) -
						parseInt(b?.data?.floor_no)
				);
				setFloors(result);
			});
		}
	}, []);
	// useEffect(() => {
	//   dispatch(getSites());
	// }, []);
	useEffect(() => {
		if (user != null) {
			dispatch(getSites("simple=1"));
		}
	}, [user]);

	const onChange = (e) => {
		if (e != 0) {
			navigate(`/sites/${e}`);
			window.location.reload(false);
		}
	};
	useLayoutEffect(() => {
		const scrollableContainer = document.querySelector(
			".ListOfFloorsWrapper"
		);

		// Initially scroll to the bottom

		// Function to scroll to the bottom when new content is added
		function scrollToBottom() {
			scrollableContainer.scrollTop = scrollableContainer.scrollHeight;
		}

		scrollToBottom();
	}, [Floors]);

	return (
		<div className="floor-flex">
			<div className="SiteCanvas">
				<div className="navBar">
					<div className="TitleBack">
						<IconButton onClick={() => navigate(-1)}>
							<KeyboardBackspaceIcon />
						</IconButton>
						<h1 className="text-xl font-medium">
							{
								value?.find((ele) => ele?.data?.id == siteId)
									?.data.name
							}
						</h1>
					</div>
					<div className="nav-end">
						<Select
							options={value}
							onChange={onChange}
							value={siteId}
							title={"Nearby Sites"}
							placeholder={"Nearby Sites"}
							key={"name"}
							area={"sites"}
						/>
						{user?.roleId ==
							process.env.REACT_APP_ADMIN_ROLE_ID && (
							<button
								className="addButton customButtomOverAll"
								onClick={() =>
									navigate(`/sites/${siteId}/floor/add`)
								}>
								Add Floor
							</button>
						)}
					</div>
				</div>
				<div className="ListOfFloorsWrapper">
					<div
						ref={ref}
						onMouseLeave={() => {
							setHoveredFloorId("");
						}}
						className=" listOfFloors">
						{Floors &&
							Floors.map((floor) => {
								return (
									<>
										<div
											onMouseEnter={(e) => {
												const windowWidth =
													ref.current.offsetWidth;
												const elementWidth = 38; // width of the element (floor)
												const mousePosition = e.pageX;
												const leftPosition =
													mousePosition -
													elementWidth;
												const rightPosition =
													mousePosition +
													elementWidth;
												const isCloserToLeft =
													leftPosition >= 30;
												const isCloserToRight =
													rightPosition <=
													windowWidth;
												const isOutOfBounds = !(
													isCloserToLeft ||
													isCloserToRight
												);
												const x = isOutOfBounds
													? isCloserToLeft
														? windowWidth -
														  elementWidth
														: 0
													: isCloserToLeft
													? rightPosition
													: leftPosition;

												console.log(e);
												setHoveredFloorId(
													floor.data.id
												);
												setHoverPosition({
													x,
													y: e.pageY - 100,
												});
											}}
											onClick={() =>
												navigate(
													`/sites/${siteId}/floor/${floor.data.id}`
												)
											}
											onMouseMove={(e) => {
												const windowWidth =
													ref.current.offsetWidth;
												const elementWidth = 38; // width of the element (floor)
												const mousePosition = e.pageX;
												const leftPosition =
													mousePosition -
													elementWidth;
												const rightPosition =
													mousePosition +
													elementWidth;
												const isCloserToLeft =
													leftPosition >= 30;
												const isCloserToRight =
													rightPosition <=
													windowWidth;
												const isOutOfBounds = !(
													isCloserToLeft ||
													isCloserToRight
												);
												const x = isOutOfBounds
													? isCloserToLeft
														? windowWidth -
														  elementWidth
														: 0
													: isCloserToLeft
													? rightPosition
													: leftPosition;

												console.log(e);
												setHoveredFloorId(
													floor.data.id
												);
												setHoverPosition({
													x,
													y: e.pageY - 100,
												});
											}}
											onMouseOver={(e) => {
												const windowWidth =
													ref.current.offsetWidth;
												const elementWidth = 38; // width of the element (floor)
												const mousePosition = e.pageX;
												const leftPosition =
													mousePosition -
													elementWidth;
												const rightPosition =
													mousePosition +
													elementWidth;
												const isCloserToLeft =
													leftPosition >= 30;
												const isCloserToRight =
													rightPosition <=
													windowWidth;
												const isOutOfBounds = !(
													isCloserToLeft ||
													isCloserToRight
												);
												const x = isOutOfBounds
													? isCloserToLeft
														? windowWidth -
														  elementWidth
														: 0
													: isCloserToLeft
													? rightPosition
													: leftPosition;

												console.log(e);
												setHoveredFloorId(
													floor.data.id
												);
												setHoverPosition({
													x,
													y: e.pageY - 100,
												});
											}}
											data-bg={
												getStatus(floor.status).color
											}
											style={{
												backgroundColor: getStatus(
													floor.status
												).color,
											}}
											key={floor.data.id}
											className={`floor`}>
											{floor &&
												floor.data &&
												floor.data.floor_no}

											{hoveredFloorId ===
												floor.data.id && (
												<FloorHoverComponent
													floorId={floor.data.id}
													position={hoverPosition}
													data={floor}
													matches={matches}
													setHoveredFloorId={
														setHoveredFloorId
													}
												/>
											)}
										</div>
										{floor.data.floor_no == 0 && (
											<Divider
												sx={{ borderBottomWidth: 10 }}
											/>
										)}
									</>
								);
							}).reverse()}
					</div>
				</div>

				{/* Render FloorHoverComponent with position prop */}
			</div>
			<AssetDrawer />
			<SwipeableEdgeDrawer />
			{/* ... (existing code) */}
		</div>
	);
}

export default FloorMap;

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#63C236",
			edge: 0xc3e1c6,
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "#FFCC49",
			edge: 0xddad01,
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "#E32B12",
			edge: 0xfdbdbd,
		};
	}
	return {
		color: "#FFCC49",
		edge: 0xffa500,
	};
};
