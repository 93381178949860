import React, { useState } from "react";

import { Divider } from "@mui/material";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";
import "./Maps.css";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const MapMarker = (props) => {
	const { status, Location, country, solution, sector, name, id, Error } =
		props;
	const { Component, color } = useStatus(status);
	return (
		<div
			className="marker-layout-Map"
			style={{ borderTop: `1.5px solid ${color}` }}>
			<div className="HeadingMarker">
				<div className="HeadingAddress">
					<Headings className="text-base">{name}</Headings>
					<Component />
				</div>

				<SubHeadings className="text-sm" color={color}>
					{getStatusMessage(status)}
				</SubHeadings>
			</div>
			<Divider />
			<div className="BottomSection">
				<div className="fx-space p-0">
					<SubHeadings className="text-base text-black">
						Solution
					</SubHeadings>
					<SubHeadings className="font-futura-book text-sm text-black font-normal">
						{solution}
					</SubHeadings>
				</div>
				<div className="fx-space p-0">
					<SubHeadings className="text-sm text-black">
						Sector
					</SubHeadings>
					<SubHeadings className="font-futura-book text-sm text-black font-normal">
						{sector}
					</SubHeadings>
				</div>
			</div>
		</div>
	);
};

const useStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		const Component = () => {
			return (
				<CheckCircleOutlineOutlinedIcon className="w-4 h-16 text-[#34A853]" />
			);
		};
		return {
			Component,
			color: "#34A853",
		};
	} else if (status >= 300 && status < 400) {
		const Component = () => {
			return <WarningAmberIcon className="text-[#ddad01] w-4 h-4" />;
		};
		return {
			Component,
			color: "rgba(221, 173, 1, 1)",
		};
	} else if (status >= 400 && status < 500) {
		const Component = () => {
			return <WarningAmberIcon className="text-[#eb4435] w-4 h-4" />;
		};
		return {
			Component,
			color: "red",
		};
	} else {
		const Component = () => {
			return (
				<CheckCircleOutlineOutlinedIcon className="w-4 h-4 text-[#FFA500]" />
			);
		};
		return {
			Component,
			color: "#FFA500",
		};
	}
};
