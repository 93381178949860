import React, { useEffect, useState } from "react";
import DynamicLineChartWeek from "../../Components/Charts/WeekChart";
import DynamicLineChart from "../../Components/Charts/AllTimeChart";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import "./Asset.css";
import { Headings } from "../../Shared/Fonts/Fonts";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { IconButton, Paper, CircularProgress } from "@mui/material";
import { getWebhooks } from "../../Store/Features/Webhook";
import DynamicLineChartMsgType from "../../Components/Charts/msgType";
import DynamicLineChartTemper from "../../Components/Charts/doorSlamTemper";
import doorOpen from "../../Assets/doorOpen.png";
import doorClose from "../../Assets/doorClosed.png";
import DynamicLineChartMagnetometer from "../../Components/Charts/magnaticReading";
import DynamicLineChartAccelerometer from "../../Components/Charts/acclerometer";
import DynamicLineChartTemperature from "../../Components/Charts/Temp";
import DynamicLineChartVBat from "../../Components/Charts/VoltageBat";
import DynamicLineChartRSSI from "../../Components/Charts/RSSI";
import DynamicLineChartSpreading from "../../Components/Charts/Spreading";

export const DevicePage = () => {
	const { device_code } = useParams();
	const { value, loading } = useSelector((state) => state.webhooks);
	const [data, setData] = useState([]);
	const [weekly, setWeekluData] = useState([]);
	const [all, setAll] = useState([]);
	const now = new Date();

	const oneWeekAgo = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
	const oneWeekAgoISO = oneWeekAgo.toISOString();

	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getWebhooks(`Sensor=${device_code}&remove=1`)).then((res) => {
			setAll(res.payload?.rows);
		});

		dispatch(
			getWebhooks(
				`Sensor=${device_code}&createdAtRange=${oneWeekAgoISO}&createdAtRange=&remove=1`
			)
		).then((res) => {
			setWeekluData(res.payload?.rows);
		});

		dispatch(
			getWebhooks(
				`limit=1&Sensor=${device_code}&field=createdAt&sort=desc&remove=1`
			)
		).then((res) => {
			setData(res.payload?.rows);
		});
	}, []);

	return (
		<div className="DevicePage">
			<div className="TitleBack">
				<IconButton onClick={() => navigate(-1)}>
					<KeyboardBackspaceIcon />
				</IconButton>
				<Headings className="text-lg">
					{device_code} Report Overview
				</Headings>
			</div>
			<div className="deviceDataArea">
				<div className="flex flex-wrap w-[70%] gap-[15px] flex-col">
					<div className="flex gap-[10px] flex-wrap">
						<GeneralPaper title={"Msg#"} data={data[0]?.msgSeqNo} />
						<GeneralPaper
							title={"Battery Status (@20C)"}
							data={data[0]?.bat_status}
						/>
						<GeneralPaper
							title={"Reset Status"}
							data={data[0]?.resetAck == 0 ? "Normal" : "Reset"}
						/>
						<GeneralPaper
							title={"Temperature"}
							data={`${data[0]?.temperature100c} C`}
						/>
						<GeneralPaper
							title={"Msg Type Last"}
							data={getMegStatus(data[0]?.msgType)}
						/>
						<GeneralPaper
							title={"Voltage"}
							data={data[0]?.vbatmv}
						/>
						<GeneralPaper
							title={"Door Timeout"}
							data={`${data[0]?.doorOpenTimeoutInMinutes} m`}
						/>
						<GeneralPaper
							title={"Report Interval"}
							data={`${data[0]?.reportingIntervalInSeconds} s`}
						/>
					</div>

					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Door Utilisation</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartWeek
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Door Utilisation</Headings>
							<Headings className="text-[#407AAD]">
								Lifetime
							</Headings>
						</div>

						<DynamicLineChart
							data={all}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Message Type</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartMsgType
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Door Slam and Door Temper</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartTemper
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Magnetometer</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartMagnetometer
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Accelerometer</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartAccelerometer
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Temperature</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartTemperature
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>VBat</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartVBat
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>RSSI</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartRSSI
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Spreading Factor</Headings>
							<Headings className="text-[#407AAD]">
								Weekly
							</Headings>
						</div>

						<DynamicLineChartSpreading
							data={weekly}
							chartTitle="Dynamic Line Chart"
							xLabel="Date"
							yLabel="Value"
						/>
					</Paper>
				</div>
				<div className="flex flex-col gap-[15px]">
					<Paper className="flex flex-col h-[300px] p-[15px] w-full">
						<div className="flex justify-between">
							<Headings>Door State (Open/Closed)</Headings>
						</div>
						<div className="flex justify-center w-full">
							{data[0]?.doorStatus == 1 ? (
								<img
									src={doorClose}
									className="w-[300px] h-full border-0 solid border-black"
									alt="Door State"
								/>
							) : (
								<img
									src={doorOpen}
									className="w-[300px] h-full border-0 solid border-black"
									alt="Door State"
								/>
							)}
						</div>
					</Paper>

					<GeneralPaper
						title={"Device Up Time"}
						data={`${data[0]?.deviceTimeInSeconds} s`}
						className="w-full"
					/>

					<GeneralPaper
						title={"Door Open Count"}
						data={data[0]?.doorOpenCount}
						className="w-full"
					/>
					<GeneralPaper
						title={"Door Slam Count"}
						data={data[0]?.doorSlamCount}
						className="w-full"
					/>
					<GeneralPaper
						title={"Door Temper Count"}
						data={data[0]?.doorTamperCount}
						className="w-full"
					/>
					<GeneralPaper
						title={"Magnetometer X"}
						data={data[0]?.magnetometerX}
						className="w-full"
					/>
					<GeneralPaper
						title={"Magnetometer Y"}
						data={data[0]?.magnetometerY}
						className="w-full"
					/>
					<GeneralPaper
						title={"Magnetometer Z"}
						data={data[0]?.magnetometerZ}
						className="w-full"
					/>
					<GeneralPaper
						title={"Accelerometer X"}
						data={`${Math.round(data[0]?.accelerometerX)} g`}
						className="w-full"
					/>
					<GeneralPaper
						title={"Accelerometer Y"}
						data={`${Math.round(data[0]?.accelerometerY)} g`}
						className="w-full"
					/>
					<GeneralPaper
						title={"Accelerometer Z"}
						data={`${Math.round(data[0]?.accelerometerZ)} g`}
						className="w-full"
					/>
					<GeneralPaper
						title={"Temperature"}
						data={data[0]?.temperature100c}
						className="w-full"
					/>
					<GeneralPaper
						title={"Temperature Maximum"}
						data={data[0]?.temperatureMax100c}
						className="w-full text-red-600"
					/>
					<GeneralPaper
						title={"Temperature Minimum"}
						data={data[0]?.temperatureMin100c}
						className="w-full text-blue-600"
					/>
					<GeneralPaper
						title={"Spreading Factor"}
						data={data[0]?.spreading_factor}
						className="w-full"
					/>
					<GeneralPaper
						title={"SNR"}
						data={`${data[0]?.snr} dB`}
						className="w-full"
					/>
					<GeneralPaper
						title={"RSSI"}
						data={`${data[0]?.rssi} dBm`}
						className="w-full"
					/>

					<GapPaper
						title={"Engineering Notes"}
						data={data}
						className="w-full"
					/>
				</div>
			</div>
		</div>
	);
};

const GeneralPaper = ({ title, data, width, color }) => {
	return (
		<Paper
			className="title flex flex-col justify-between h-[150px] p-[15px] box-border"
			sx={{
				width: width ? width : 250,
			}}>
			<Headings className="text-base">{title}</Headings>
			<Headings className="text-2xl" color={color}>
				{data}
			</Headings>
		</Paper>
	);
};
const GapPaper = ({ title, data, width, color }) => {
	return (
		<Paper
			className="title flex flex-col justify-between h-auto p-[30px] box-border"
			sx={{
				width: width ? width : 250,
			}}>
			<Headings className="text-base">{title}</Headings>
			<Headings fontSize={16}>Gaps (mm)</Headings>
			<div>
				<Headings className="text-base" color={color}>
					Top Left-{data[0]?.top_left_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Top Right-{data[0]?.top_right_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Bottom Left-{data[0]?.bottom_left_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Bottom Right-{data[0]?.bottom_right_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Hinge Top-{data[0]?.hinge_top_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Hinge Bottom-{data[0]?.hinge_bottom_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Swing Top-{data[0]?.swing_top_measurement}
				</Headings>
				<Headings className="text-base" color={color}>
					Swing Bottom-{data[0]?.swing_bottom_measurement}
				</Headings>
			</div>
		</Paper>
	);
};

const getMegStatus = (msgType) => {
	switch (msgType) {
		case 0:
			return "Normal Status";
		case 1:
			return "Door Time Out Alert";
		case 2:
			return "Commissioning";
		case 3:
			return "Commission Complete";
		case 4:
			return "Commission Failed";
		default:
			return "Unknown";
	}
};

const doorStatus = (status) => {
	switch (status) {
		case 0:
			return <img src={doorClose} alt="Door Close" />;
		case 1:
			return <img src={doorOpen} alt="Door Open" />;
		default:
			return "Unknown";
	}
};
