import React, { useEffect } from "react";
import { Sidebar } from "./Navigations/SideBar";
import { AppBar } from "./Navigations/AppBar";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { profile } from "../../Store/Features/Auth";
import { useNavigate } from "react-router-dom";
import MobileNavAppBar from "./Navigations/MobileNavBar";

import { useJsApiLoader } from "@react-google-maps/api";
import jsCookie from "js-cookie";

import "./Layout.css";
import useMediaQuery from "@mui/material/useMediaQuery";

export const Layout = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const matches = useMediaQuery("(max-width:769px)");

	const { isLoaded } = useJsApiLoader({
		id: "google-map-script",
		libraries: ["places"],
		googleMapsApiKey: "AIzaSyBp62nI9KBGuDsGKlBGxXa54izDRyZacZM",
	});

	return (
		<div className="Layout">
			<MobileNavAppBar />
			<Sidebar />
			{matches ? (
				<div className="mobileContent">
					<Outlet />
				</div>
			) : (
				<div className="content">
					<AppBar />
					<Outlet />
				</div>
			)}
		</div>
	);
};
