import React, { useEffect, useRef, useState } from "react";
import { fabric } from "fabric";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getFloor } from "../../Store/Features/Floors";
import { getDoorsofFloor } from "../../Store/Features/Door";
import { original } from "@reduxjs/toolkit";

// function calculateScalingFactors(
//   originalWidth,
//   originalHeight,
//   desiredWidth,
//   desiredHeight
// ) {
//   const scaleWidth = desiredWidth / originalWidth;
//   const scaleHeight = desiredHeight / originalHeight;

//   return { scaleWidth, scaleHeight };
// }

const CanvasLoader = ({ parentRef }) => {
	// const { height, width } = useWindowDimensions();
	// const width = parentRef.current?.clientWidth
	// const height = parentRef.current?.clientHeight
	const [width, setWidth] = useState();
	const [height, setHeight] = useState();

	const dispatch = useDispatch();
	const { assetId, floorId } = useParams();

	const canvasRef = useRef();
	const [image, setImage] = useState();
	const loadCanvasData = async () => {
		const data = await dispatch(getFloor(floorId)).then((res) => {
			return res.payload?.floor_data;
		});

		if (data) {
			try {
				// Create a new Fabric.js canvas
				const canvas = new fabric.Canvas();

				// Load the canvas data onto the Fabric.js canvas
				canvas.loadFromJSON(JSON.parse(data), async () => {
					const back = canvas.backgroundImage;

					canvas.setWidth(back.width);
					canvas.setHeight(back.height);
					// canvas.setBackgroundImage(
					//   backgroundImage
					// );

					// canvas.getObjects().forEach((obj) => {
					//   obj.scale(obj.scaleX *  (scale*0.8), obj.scaleY* (scale*0.8));
					//   obj.left = obj.left * (scale*0.8);
					//   obj.top = obj.top * (scale*0.8);
					// });
					// canvas.centerObject(backgroundImage1)

					const objects = canvas.getObjects();
					let temp = [...objects];
					canvas.remove(...objects);
					const res = await dispatch(getDoorsofFloor(floorId));
					for (let i = 0; i < res.payload.data.length; i++) {
						let door = temp.find(
							(obj) =>
								obj?.asset?.id == assetId ||
								obj?.asset?.data?.id == assetId
						);

						if (door) {
							// console.log("controls", temp[i]);

							if (door.type == "group") {
								door.asset = res.payload.data[i];
								door.selectable = false;
								door.evented = false;
								door.set(
									"fill",
									getStatus(res.payload.data[i].status).color
								);

								door.getObjects()[0].fill = getStatus(
									res.payload.data[i].status
								).color;
							}
							door.selectable = false;
							door.evented = false;
							canvas.add(door);
						}

						//  else {
						//   console.log("check",temp[i].asset?.id,temp[i].asset?.data);
						//   temp[i].asset = {};
						//   const { color } = getStatus(res.payload.data[j].status);
						//   temp[i].asset.id = res.payload.data[j].data?.id;
						//   temp[i].hasControls = false;
						//   if (temp[i].type == "group") {
						//     temp[i].asset = res.payload.data[j];

						//     // canvas.item(i).hasControls = false;
						//     temp[i].getObjects()[0].fill = color;
						//     temp[i].selectable = isEditMode; // Toggle selectability of objects
						//     temp[i].evented = true;
						//     temp[i].on("mousedblclick", handleMarkerDoubleClick);
						//     temp[i].on("mouseover", handleMarkerMouseEnter);
						//     temp[i].on("mouseout", handleMarkerMouseLeave);
						//     temp[i].on("moving", moveHandler);
						//     {
						//       matches && temp[i].on("mousedown", handleSingleClick);
						//     }
						//   }
						// }
					}
					canvas.renderAll();

					canvas.setZoom(0.9999);

					// console.log("Marker", objects[0]);

					setImage(canvas.toDataURL());
				});
			} catch (error) {
				console.error("Error loading canvas data:", error);
			}
		}
	};

	useEffect(() => {
		if (width && height) {
			console.log("dimensions", height, width);
			loadCanvasData();
		}
	}, [width, height]);

	useEffect(() => {
		setWidth(parentRef.current?.clientWidth);
		setHeight(parentRef.current?.clientHeight);
	}, [parentRef]);

	return (
		<>
			<div className="hidden">
				<canvas ref={canvasRef} />
			</div>

			<img
				className="w-full h-full"
				width={500}
				height={500}
				src={image}
				alt="Img"
			/>
		</>
	);
};

export default CanvasLoader;

const getStatus = (stat) => {
	console.log("status", stat);
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "red",
		};
	} else {
		return {
			color: "#DD6E0F",
			background: "rgba(52, 168, 83, 0.1)",
		};
	}
};

function useWindowDimensions() {
	const hasWindow = typeof window !== "undefined";

	function getWindowDimensions() {
		const width = hasWindow ? window.innerWidth : null;
		const height = hasWindow ? window.innerHeight : null;
		return {
			width,
			height,
		};
	}

	const [windowDimensions, setWindowDimensions] = useState(
		getWindowDimensions()
	);

	useEffect(() => {
		if (hasWindow) {
			function handleResize() {
				setWindowDimensions(getWindowDimensions());
			}

			window.addEventListener("resize", handleResize);
			return () => window.removeEventListener("resize", handleResize);
		}
	}, [hasWindow]);

	return windowDimensions;
}
