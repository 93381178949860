import axios from "axios";
import { toast } from "react-toastify";
import jsCookie from "js-cookie";
import { redirect } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { API_URL } from "./Functions/utils";
// import store from '../Store/Store';

// Handle common errors
function handleError(error) {
	if (error.request.status == 401) {
		toast.error(
			error.response
				? error.response.data
					? error.response.data
					: error.message
				: "An error occurred",
			{
				toastId: "errorsId",
			}
		);
		jsCookie.remove("token");
		window.location.reload(false);
		return <Navigate to="/login" replace />;
	} else {
		toast.error(
			error.response
				? error.response.data
					? error.response.data
					: error.message
				: "An error occurred",
			{
				toastId: "errorsId",
			}
		);
		throw error;
	}
}

function getAuthHeader(isFile) {
	// const token = store.getState().auth.token
	const token = jsCookie.get("token");
	const header = {
		Authorization: `Bearer ${token}`,
		contentType: "application/json",
	};

	if (isFile) {
		header.contentType = "multipart/form-data";
	}
	return header;
}

// GET request
export async function getApi(url, isPrivate, hooks) {
	const options = {};
	if (isPrivate) {
		// const token = store.getState().auth.token;
		if (hooks) {
			options.headers = {
				Authorization: `Basic aGFybW9ueWZpcmU6aGFybW9ueWZpcmVAMTIz`,
				ontentType: "application/json",
			};
		} else {
			options.headers = getAuthHeader();
		}
	}

	return axios
		.get(API_URL() + `${url}`, options)
		.then((response) => response.data)
		.catch(handleError);
}

// POST request
export async function postApi(url, isPrivate, data) {
	const options = {};
	if (isPrivate) {
		// const token = store.getState().auth.token;
		options.headers = getAuthHeader();
	}
	console.log("POST request", API_URL());
	return axios
		.post(API_URL() + `${url}`, data, options)
		.then((response) => response.data)
		.catch(handleError);
}

// PUT request
export async function putApi(url, isPrivate, data) {
	const options = {};
	if (isPrivate) {
		options.headers = getAuthHeader();
	}

	return axios
		.put(API_URL() + `${url}`, data, options)
		.then((response) => response.data)
		.catch(handleError);
}

// DELETE request
export async function deleteApi(url, isPrivate) {
	const options = {};
	if (isPrivate) {
		options.headers = getAuthHeader();
	}

	return axios
		.delete(API_URL() + `${url}`, options)
		.then((response) => response.data)
		.catch(handleError);
}

export async function uploadService(url, isPrivate, formData) {
	const options = {};
	if (isPrivate) {
		// const token = store.getState().auth.token;
		options.headers = getAuthHeader(true);
	}

	return axios
		.post(API_URL() + `${url}`, formData, options)
		.then((response) => response.data)
		.catch(handleError);
}
