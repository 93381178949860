import React, { useState, useEffect } from "react";
import authLogo from "../../Assets/authLogo.svg";
import {
	Card,
	CardContent,
	TextField,
	Button,
	InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import IconButton from "@mui/material/IconButton";
import "./Auth.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../Store/Features/Auth";
import { Headings, Text } from "../../Shared/Fonts/Fonts";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLocation } from "react-router-dom";

export function ResetPassword() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [passwordError, setPasswordError] = useState("");
	const loading = useSelector((state) => state.auth.loading);
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const token = searchParams.get("token");

	const handleLogin = () => {
		// Validate fields before performing login action
		if (!email) {
			setEmailError("Password is required");
			return;
		}
		if (password.length < 6) {
			setEmailError("Password should be at least 6 characters");
			return;
		}
		if (!password) {
			setPasswordError("Password is required");
			return;
		}
		if (password.length < 6) {
			setPasswordError("Password should be at least 6 characters");
			return;
		}

		if (email != password) {
			setPasswordError("Password and Confirm Password should be same");
			return;
		}
		dispatch(resetPassword({ password: email, token: token })).then(
			(res) => {
				if (!res.error) {
					navigate("/");
				}
			}
		);
	};
	const handleClickShowPassword = () => setShowPassword((show) => !show);

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	useEffect(() => {
		document.title = "Password Reset";
	}, []);

	const isValidEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<Card className="login-card">
			<CardContent className="login-container">
				<div className="logo-container flex justify-center">
					<img src={authLogo} alt="Logo" />
				</div>
				<div className="HeadingText">
					<Headings className="text-lg">
						Welcome to Harmony Fire
					</Headings>
					<Headings className="text-sm text-[#637381]">
						Please Enter New Passowrd
					</Headings>
				</div>
				<TextField
					label="Password"
					placeholder="Enter your password"
					fullWidth
					className="input-field"
					variant="standard"
					value={email}
					type={showPassword ? "text" : "password"}
					onChange={(e) => {
						setEmail(e.target.value);
						setEmailError("");
					}}
					InputProps={{
						endAdornment: (
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end">
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						),
					}}
					error={!!emailError}
					helperText={emailError}
				/>
				<TextField
					label="Confirm Password"
					variant="standard"
					placeholder="Enter your confirm password"
					type={showPassword ? "text" : "password"}
					fullWidth
					className="input-field"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
						setPasswordError("");
					}}
					error={!!passwordError}
					helperText={passwordError}
					InputProps={{
						endAdornment: (
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end">
								{showPassword ? (
									<VisibilityOff />
								) : (
									<Visibility />
								)}
							</IconButton>
						),
					}}
				/>

				<Button
					variant="contained"
					color="primary"
					className="login-button customButtomOverAll capitalize py-[13px] px-[22px]"
					onClick={handleLogin}>
					{loading ? (
						<CircularProgress className="text-inherit" />
					) : (
						<Headings className="text-white font-bold text-[15px]">
							Set Password
						</Headings>
					)}
				</Button>
			</CardContent>
		</Card>
	);
}
