import React, { useEffect, useRef, useState } from "react";
import "./Asset.css";
import {
	getStatusMessage,
	getBatteryStatus,
	getStatusMessages,
} from "../../Utils/Functions/getStatusMessages";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import MarkerModal from "../../Components/Modal/MarkerModal";
import { useParams, useNavigate } from "react-router-dom";
import { Paper } from "@mui/material";
import NotesModal from "../../Components/Modal/Notes";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { IconButton } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import Select from "../../Shared/CustomElement/DropDown";
import { Headings, SubHeadings } from "../../Shared/Fonts/Fonts";
import Divider from "@mui/material/Divider";
import { Button } from "../../Shared/CustomElement/Button";
import { AssetSidePanel } from "../../Components/Drawers/AssetSidePanel";
import { getSensorsbyDoor } from "../../Store/Features/Sensors";
import { useDispatch } from "react-redux";
import { getDoor } from "../../Store/Features/Door";
import { getFloor } from "../../Store/Features/Floors";
import { useSelector } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { getSites } from "../../Store/Features/Sites";

export const AssetInspection = () => {
	const navigate = useNavigate();
	const { siteId, floorId, assetId } = useParams();
	const { value } = useSelector((state) => state.sites);
	const path = useParams().pathName;
	const dispatch = useDispatch();
	const [sensors, setSensors] = useState([]);
	const [door, setDoor] = useState({});
	const matches = useMediaQuery("(max-width:769px)");
	const [floor, setFloor] = useState({});
	const [doors, setDoors] = useState([]);

	useEffect(() => {
		dispatch(getDoor(assetId)).then((res) => {
			setDoor(res.payload);
		});
	}, []);
	useEffect(() => {
		dispatch(getSensorsbyDoor(assetId)).then((res) => {
			setSensors(res.payload.rows);
		});
	}, []);

	return (
		<div className="AssetPage">
			<div className="StatusPart">
				<div className="navBar">
					<div className="navBar w-full px-[10px] box-border">
						<div className="TitleBack">
							<IconButton onClick={() => navigate(-1)}>
								<KeyboardBackspaceIcon />
							</IconButton>
							<Headings className="text-lg">
								{
									value.find((ele) => ele.data.id == siteId)
										?.data.name
								}
							</Headings>
						</div>
					</div>
				</div>
				<div className="Documents">
					<div className="options">
						<Button
							className="text-[#105998]"
							title={"Manufacture Certificate"}
							hide={
								door?.manufacturer_certificate?.length > 0
									? false
									: true
							}
							href={`${
								door.manufacturer_certificate?.length > 0
									? door?.manufacturer_certificate[0]
											.publicUrl
									: null
							}`}
						/>
						<Button
							className="text-[#105998]"
							title={"Install Certificate"}
							hide={
								door?.installation_certificate?.length > 0
									? false
									: true
							}
							href={`${
								door.installation_certificate?.length > 0
									? door?.installation_certificate[0]
											.publicUrl
									: null
							}`}
						/>
						<Button
							className="text-[#105998]"
							title={"Maintenance Records"}
							hide={
								door?.maintaince_records?.length > 0
									? false
									: true
							}
							href={`${
								door.maintaince_records?.length > 0
									? door?.maintaince_records[0].publicUrl
									: null
							}`}
						/>
					</div>
				</div>
				{matches ? (
					<div className="w-full">
						<Accordion
							className="bg-transparent border border-solid border-black"
							elevation={0}>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1a-content"
								id="panel1a-header"
								className="w-full">
								<Headings>Status Report</Headings>
							</AccordionSummary>
							<AccordionDetails>
								<div className="status-content">
									<DoorReport {...door} />
									<DoorUtilisation {...door} />
									<DoorUtilisationInterval {...door} />
								</div>
							</AccordionDetails>
						</Accordion>
						{sensors?.map((sensor) => {
							return (
								<Accordion
									className="bg-transparent border border-solid border-black"
									elevation={0}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel2a-content"
										id="panel2a-header"
										className="w-full">
										<Headings>
											{`${sensor.location} Sensors`}{" "}
											{sensor.sensor_code}
										</Headings>
									</AccordionSummary>
									<AccordionDetails>
										<TopSensor {...sensor} />
									</AccordionDetails>
								</Accordion>
							);
						})}
					</div>
				) : (
					<div className="status">
						<Headings className="text-lg font-extrabold">
							Status Report
						</Headings>
						<div className="status-content">
							<DoorReport {...door} />
							<DoorUtilisation {...door} />
							<DoorUtilisationInterval {...door} />
						</div>
						<div className="sensor-content">
							{sensors?.map((sensor) => {
								return <TopSensor {...sensor} />;
							})}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

const DoorReport = (props) => {
	const {
		status,
		last_inspection,
		due_inspection,
		week_Utilisation,
		lifetime_Utilisation,
		report_interval,
		open_interval,
	} = props;
	return (
		<Paper className="reportCards py-6 px-8 border border-solid border-[#eeeeee] gap-4 flex-col flex w-1/4">
			<Tooltip title={`${getStatusMessages(status)}`}>
				<div className="DoorReport-Upper">
					<div
						className="w-2 h-2 rounded-full"
						style={{
							backgroundColor: getStatus(status).color,
						}}
					/>
					<Headings className="text-base text-[#637381] font-medium">
						Report
					</Headings>
					<Headings className="text-base text-[#212b36] font-medium">
						{getStatusMessage(status)}
					</Headings>
				</div>
			</Tooltip>
			<div className="DoorReport-down">
				<Headings className="text-base text-[#637381] font-medium">
					Compliance Inspection
				</Headings>
				<div className="DoorReport-Upper">
					<div className="w-2 h-2 bg-[#34a853] rounded-full" />
					<Headings className="text-base text-[#637381] font-medium">
						Last
					</Headings>
					<Headings className="text-base text-[#212b36] font-medium">
						{last_inspection || "N/A"}
					</Headings>
				</div>
				<div className="DoorReport-Upper">
					<div className="w-2 h-2 bg-[#34a853] rounded-full" />
					<Headings className="text-base text-[#637381] font-medium">
						Due
					</Headings>
					<Headings className="text-base text-[#212b36] font-medium">
						{due_inspection || "N/A"}
					</Headings>
				</div>
			</div>
		</Paper>
	);
};

const DoorUtilisation = (props) => {
	const { week_Utilisation, lifetime_Utilisation, createdAt } = props;
	return (
		<Paper className="reportCards py-6 px-8 border border-solid border-[#eeeeee] gap-4 flex-col flex w-1/4">
			<div className="DoorReport-down">
				<Headings className="text-base text-[#637381] font-medium">
					Door utilisation
				</Headings>
				<div className="DoorReport-Upper">
					<div className="w-2 h-2 bg-[#34a853] rounded-full" />
					<Headings className="text-base text-[#637381] font-medium">
						Last Week
					</Headings>
					<Headings className="text-base text-[#212b36] font-medium">
						{week_Utilisation || "N/A"}
					</Headings>
				</div>
				<div className="DoorReport-Upper">
					<div className="w-2 h-2 bg-[#34a853] rounded-full" />
					<Headings className="text-base text-[#637381] font-medium">
						Lifetime
					</Headings>
					<div className="flex gap-[5px] items-center">
						<Headings className="text-base text-[#212b36] font-medium">
							{lifetime_Utilisation || "N/A"}{" "}
						</Headings>
						<Headings className="text-sm text-[#637381] font-medium">
							({createdAt?.split("T")[0]})
						</Headings>
					</div>
				</div>
			</div>
		</Paper>
	);
};

const DoorUtilisationInterval = (props) => {
	const { report_interval, open_interval } = props;
	return (
		<Paper className="reportCards py-6 px-8 border border-solid border-[#eeeeee] gap-4 flex-col flex w-1/4">
			<div className="DoorReport-down">
				<Headings className="text-base text-[#637381] font-medium">
					Door utilisation
				</Headings>
				<div className="DoorReport-Upper">
					<div className="w-2 h-2 bg-[#34a853] rounded-full" />
					<Headings className="text-base text-[#637381] font-medium">
						Report Interval
					</Headings>
					<Headings className="text-base text-[#212b36] font-medium">
						{report_interval || "N/A"}
					</Headings>
				</div>
				<div className="DoorReport-Upper">
					<div className="w-2 h-2 bg-[#34a853] rounded-full" />
					<Headings className="text-base text-[#637381] font-medium">
						Open Timeout
					</Headings>
					<Headings className="text-base text-[#212b36] font-medium">
						{open_interval || "N/A"}
					</Headings>
				</div>
			</div>
		</Paper>
	);
};

const TopSensor = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const {
		status,
		location,
		sensor_code,
		id,
		battery,
		complianceDate,
		complianceDue,
		open_interval,
		report_interval,
		lifetime_Utilisation,
		week_Utilisation,
	} = props;
	const matches = useMediaQuery("(max-width:769px)");
	return (
		<>
			<div
				className="reportCards flex flex-col gap-[5px] w-[31%]"
				onClick={() => setIsOpen(true)}>
				<div className="flex gap-[10px]">
					{!matches && (
						<>
							<Headings className="capitalize text-base font-medium">
								{location} Sensor
							</Headings>
							<Headings className="capitalize text-base font-extrabold text-[#637381]">
								{sensor_code}
							</Headings>
						</>
					)}
				</div>
				<Paper className="py-6 px-8 border border-solid border-[#eeeeee] gap-4 flex-col flex">
					<Tooltip title={`${getStatusMessages(status)}`}>
						<div className="DoorReport-Upper">
							<div
								className="w-2 h-2 rounded-full"
								style={{
									backgroundColor: getStatus(status).color,
								}}
							/>
							<Headings className="text-base text-[#637381] font-medium">
								Report
							</Headings>
							<Headings className="capitalize text-base font-medium text-[#212b36]">
								{getStatusMessage(status)}
							</Headings>
						</div>
					</Tooltip>
					<div className="DoorReport-Upper">
						<div
							className="w-2 h-2 rounded-full"
							style={{
								backgroundColor: getBatteryStatus(status).color,
							}}
						/>
						<Headings className="text-base text-[#637381] font-medium">
							Battery
						</Headings>
						<Headings className="text-base text-[#212b36] font-medium">
							{getBatteryStatus(status).message}
						</Headings>
					</div>
					<div className="DoorReport-down">
						<Headings className="text-base text-[#637381] font-medium">
							Compliance Inspection
						</Headings>
						<div className="DoorReport-Upper">
							<Headings className="text-base text-[#637381] font-medium">
								Last
							</Headings>
							<Headings className="text-base text-[#212b36] font-medium">
								{complianceDate || "N/A"}
							</Headings>
						</div>
						<div className="DoorReport-Upper">
							<Headings className="text-base text-[#637381] font-medium">
								Due
							</Headings>
							<Headings className="text-base text-[#212b36] font-medium">
								{complianceDue || "N/A"}
							</Headings>
						</div>
					</div>
				</Paper>
			</div>
			<NotesModal open={isOpen} setOpen={setIsOpen} id={id} />
		</>
	);
};

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
			background: "rgba(221, 173, 1, 0.1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "rgba(235, 67, 53, 0.1)",
		};
	} else {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	}
};
