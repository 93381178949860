import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./SidePanels.css";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { useDispatch } from "react-redux";
import {
	getSite,
	getSensorsOfSite,
	getDoorsOfSite,
} from "../../Store/Features/Sites";
import { useParams } from "react-router-dom";

export default function AssetDrawer(props) {
	const [site, setSite] = useState();
	const dispatch = useDispatch();
	const { siteId } = useParams();
	const { open, setOpen, asset } = props;
	const [sensors, setSensors] = useState([]);
	const [doors, setDoors] = useState([]);

	useEffect(() => {
		dispatch(getSite(siteId)).then((res) => {
			setSite(res.payload);
		});
	}, []);

	return (
		<Box className="floorInfoDrawer h-[670px] bg-white rounded-xl">
			<Box className="marker-layout">
				<Box className="heading-marker">
					<Headings className="text-lg">{site?.name || ""}</Headings>
				</Box>
				<Divider />

				<div className="fx-space">
					<SubHeadings className="text-base text-black font-medium leading-9">
						Building Type
					</SubHeadings>
					<SubHeadings className="font-futura-book text-base text-[#212B36] font-normal leading-9">
						{site?.sector}
					</SubHeadings>
				</div>
				<div className="fx-space">
					<SubHeadings className="leading-9 text-base text-black font-medium">
						No. of doors
					</SubHeadings>
					<SubHeadings className="leading-9 text-base font-futura-book text-[#212B36] font-normal">
						{site?.doorsCount}
					</SubHeadings>
				</div>

				<div className="fx-space">
					<SubHeadings className="leading-9 text-base text-black font-medium">
						No. of sensors
					</SubHeadings>
					<SubHeadings className="leading-9 text-base font-futura-book text-[#212B36] font-normal">
						{site?.sensorsCount}
					</SubHeadings>
				</div>
				<div className="fx-space">
					<SubHeadings className="leading-9 text-base text-black font-medium">
						Address
					</SubHeadings>
					<div className="w-[40%]">
						<SubHeadings className="leading-9 text-base font-futura-book text-[#212B36] font-normal">
							{site?.address}
						</SubHeadings>
					</div>
				</div>
			</Box>
		</Box>
	);
}
