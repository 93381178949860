// ImageUploadButton.js
import React from 'react';
import { Button, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { uploadDoorImage } from "../../Store/Features/Door";
import { API_URL } from "../../Utils/Functions/utils";

export const ImageUploadButton = ({ onChange, name, path, form }) => {
  const dispatch = useDispatch();
  const { imageLoading } = useSelector((state) => state.doors);

  const handleImageChange = (event) => {
    const selectedFile = event.target.files[0];
    const extension = extractExtensionFrom(selectedFile.name);
    const id = uuidv4();
    const filename = `${id}.${extension}`;
    const privateUrl = `${path}/${filename}`;

    const publicUrl = API_URL()+`file/download?privateUrl=${privateUrl}`;
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("filename", filename);

    dispatch(uploadDoorImage(formData)).then((res) => {
      onChange("door_image", {
        id: id,
        name: selectedFile.name,
        sizeInBytes: selectedFile.size,
        privateUrl,
        publicUrl,
        new: true,
      });
    });
  };

  return (
    <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="image-upload-input"
        type="file"
        onChange={handleImageChange}
      />
      <label htmlFor="image-upload-input">
        <Button
          className="customButtomOverAll"
          variant="contained"
          component="span"
        >
          {imageLoading ? <CircularProgress /> : "Upload Image"}
        </Button>
      </label>
      <img
        src={form.door_image?.publicUrl}
        style={{
          display: form.door_image?.publicUrl ? null : "none",
          width: 100,
          height: 100,
        }}
        alt="Door"
      />
    </div>
  );
};

function extractExtensionFrom(filename) {
  if (!filename) {
    return null;
  }

  const regex = /(?:\.([^.]+))?$/;
  return regex.exec(filename)[1];
}