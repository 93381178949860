import { createSlice } from "@reduxjs/toolkit";
import { postApi, getApi, deleteApi, putApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";

export const getRoles = createAsyncThunk(
	"roles/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi("roles", true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getRole = createAsyncThunk(
	"role/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`roles/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const addRoles = createAsyncThunk(
	"role/add",
	async (credentials, thunkAPI) => {
		try {
			const response = await postApi(`roles`, true, credentials);

			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const updateRole = createAsyncThunk(
	"role/update",
	async (credentials, thunkAPI) => {
		try {
			const response = await putApi(
				`roles/${credentials.id}`,
				true,
				credentials.data
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const deleteRole = createAsyncThunk(
	"role/delete",
	async (credentials, thunkAPI) => {
		try {
			const response = await deleteApi(`roles/${credentials}`, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);
const initialState = {
	value: [],

	loading: false,
	error: null,
};

export const Roles = createSlice({
	name: "roles",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(getRoles.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getRoles.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.rows;
		});
		builder.addCase(getRoles.rejected, (state, action) => {
			state.loading = false;

			state.error = action.payload;
		});
		builder.addCase(updateRole.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(updateRole.fulfilled, (state, action) => {
			state.loading = false;
			toast.success("Updated Role Successfully");
		});
		builder.addCase(updateRole.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
		builder.addCase(addRoles.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(addRoles.fulfilled, (state, action) => {
			state.loading = false;
			toast.success("Created Role Successfully");
		});
		builder.addCase(addRoles.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
	},
});
