import { createSlice } from "@reduxjs/toolkit";
import { getApi } from "../../Utils/serviceWorker";
import { createAsyncThunk } from "@reduxjs/toolkit";
import jsCookie from "js-cookie";
import { toast } from "react-toastify";

export const getWebhooks = createAsyncThunk(
	"webhooks/get",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(`webhook?${credentials}`, true, true);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

export const getSensorsbyCode = createAsyncThunk(
	"sensors/Org",
	async (credentials, thunkAPI) => {
		try {
			const response = await getApi(
				`sensors/eventsagainstorganization/${credentials}`,
				true
			);
			return response;
		} catch (error) {
			return thunkAPI.rejectWithValue(error.response.data);
		}
	}
);

const initialState = {
	value: [],
	loading: false,
	error: null,
	count: 0,
};

export const Webhooks = createSlice({
	name: "webhooks",
	initialState,
	reducers: {
		reset: () => initialState,
	},
	extraReducers: (builder) => {
		builder.addCase(getWebhooks.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getWebhooks.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.rows;
			state.count = action.payload.count;
		});
		builder.addCase(getWebhooks.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
		builder.addCase(getSensorsbyCode.pending, (state) => {
			state.loading = true;
			state.error = null;
		});
		builder.addCase(getSensorsbyCode.fulfilled, (state, action) => {
			state.loading = false;

			state.value = action.payload.rows;
			state.count = action.payload.count;
		});
		builder.addCase(getSensorsbyCode.rejected, (state, action) => {
			state.loading = false;
			state.error = action.payload;
		});
	},
});
