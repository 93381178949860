import React, { useState } from "react";
import authLogo from "../../Assets/authLogo.svg";
import {
	Card,
	CardContent,
	TextField,
	Button,
	IconButton,
	InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./Auth.css";
import * as xlsx from "xlsx";

import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { register, checkApi } from "../../Store/Features/Auth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

var currentTime = new Date();

function Register() {
	const dispatch = useDispatch();
	const { value, loading } = useSelector((state) => state.auth);
	const [fullName, setFullName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [error, setError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const navigate = useNavigate();

	const handleRegister = () => {
		if (!email) {
			setError("Email is required");
			return;
		}
		if (!isValidEmail(email)) {
			setError("Invalid email format");
			return;
		}
		if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/.test(password)) {
			setPasswordError(
				"Password must contain at least one lowercase letter, one uppercase letter, and one symbol."
			);
			return;
		}

		dispatch(
			register({
				organizationName: fullName,
				email: email,
				password: password,
				registration_date: currentTime,
				last_login_date: currentTime,
				hashedPassword: "",
			})
		).then((res) => {
			if (!res.error) {
				navigate("/login");
			}
		});
	};

	const isValidEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	useEffect(() => {
		document.title = "Register";
	}, []);

	const handleTogglePasswordVisibility = () => {
		setShowPassword(!showPassword);
	};

	return (
		<Card className="login-card">
			<CardContent>
				<div className="logo-container flex justify-center">
					<img src={authLogo} alt="Logo" />
				</div>
				<h2 className="login-heading">Register</h2>
				<p className="login-subtext">
					Please enter your information to create an account
				</p>
				<TextField
					label="Organisation"
					placeholder="Enter Organisation name"
					variant="standard"
					fullWidth
					className="input-field"
					value={fullName}
					onChange={(e) => setFullName(e.target.value)}
				/>
				<TextField
					label="Email"
					placeholder="Enter your email address"
					fullWidth
					variant="standard"
					className="input-field"
					value={email}
					onChange={(e) => setEmail(e.target.value)}
				/>
				<TextField
					label="Password"
					type={showPassword ? "text" : "password"}
					variant="standard"
					placeholder="Enter your password"
					fullWidth
					className="input-field"
					value={password}
					onChange={(e) => {
						setPassword(e.target.value);
						setPasswordError("");
					}}
					error={Boolean(passwordError)}
					helperText={
						passwordError ? passwordError : error ? error : ""
					}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									onClick={handleTogglePasswordVisibility}>
									{showPassword ? (
										<VisibilityOff />
									) : (
										<Visibility />
									)}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>

				<Button
					variant="contained"
					color="primary"
					className="login-button customButtomOverAll"
					onClick={handleRegister}>
					{loading ? (
						<CircularProgress color="inherit" />
					) : (
						"Register"
					)}
				</Button>
				<div className="register-container">
					<div className="line"></div>
					<p className="line-text">Or</p>
					<div className="line"></div>
				</div>
				<div className="register-container">
					<p className="register-text">
						If you already have an account{" "}
						<Link className="register-now-link" to="/login">
							Login
						</Link>
					</p>
				</div>
			</CardContent>
		</Card>
	);
}

export default Register;
