import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import "./Forms.css";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import counterClockWise from "../../Assets/counterClockWise.png";
import clockWiseDoor from "../../Assets/clockWiseDoor.png";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import RotateRightIcon from "@mui/icons-material/RotateRight";
export const DoorInformation = ({ form, setForm, data }) => {
	return (
		<div className="SensorConnection">
			<Text>Data Recieved! Please Enter Door Information</Text>
			<div className="flex gap-[10px] justify-center w-full">
				<div className="Content w-full justify-start">
					<div className="firstHalfDoorInformation">
						<div className="flex flex-col gap-[10px]">
							<Text>Opening Direction</Text>
						</div>
						<ImageRadios
							form={form}
							setForm={setForm}
							name="direction"
						/>
					</div>
					<Divider orientation="vertical" flexItem></Divider>
					<div className="SecondHalfDoorInformation">
						<div className="contentForm">
							<Headings>
								Confirm Door Size
								<small className="p-[2px] text-[#eb4335] bg-[#eb4335] bg-opacity-10">
									(Mtr)
								</small>
							</Headings>

							<div className="ButtonsGroup">
								<Input
									form={form}
									setForm={setForm}
									name="width"
								/>
								<Input
									form={form}
									setForm={setForm}
									name="height"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
const Input = ({ setForm, name, form }) => {
	return (
		<div className="SensorInputs">
			<labe>
				{" "}
				<span className="capitalize">{name}</span>
				<input
					placeholder={`${name}`}
					className="inputSensors form-controll"
					value={form[name]}
					onChange={(e) =>
						setForm({ ...form, [name]: e.target.value })
					}
				/>
			</labe>
		</div>
	);
};
function ImageRadios({ form, setForm, name }) {
	const handleChange = (event) => {
		setForm({ ...form, direction: event.target.value });
	};
	return (
		<FormControl fullWidth>
			<RadioGroup
				value={form[name]}
				onChange={handleChange}
				name={name}
				className="flex flex-row justify-between py-0 pl-[10px] pr-0">
				<FormControlLabel
					value="CW"
					control={<Radio className="p-0" />}
					label={
						<div className="flex flex-row justify-center items-end">
							<RotateLeftIcon />
						</div>
					}
				/>
				<FormControlLabel
					value="CCW"
					control={<Radio className="p-0" />}
					label={
						<div className="flex flex-row justify-center items-center">
							<RotateRightIcon />
						</div>
					}
				/>
			</RadioGroup>
		</FormControl>
	);
}
