import React, { useState, useEffect } from "react";
import {
	PDFDownloadLink,
	Document,
	Page,
	Text,
	StyleSheet,
} from "@react-pdf/renderer";
import { format } from "date-fns";
import { jsPDF } from "jspdf";
import * as xlsx from "xlsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getReport } from "../../Store/Features/notifications";
import { toast } from "react-toastify";
import { TextField, Button, Grid, Paper, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { getDoorsofFloor } from "../../Store/Features/Door";
import { getFloorsOfSite } from "../../Store/Features/Floors";
import { getSites, getSitesOfOrganization } from "../../Store/Features/Sites";

import Select from "react-select/creatable";

const ReportGenerator = () => {
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const [siteIds, setSiteIds] = useState([]);
	const [floorIds, setFloorIds] = useState([]);
	const [doorIds, setDoorIds] = useState([]);
	const dispatch = useDispatch();

	// const loading = useSelector((state) => state.sensors.loading);
	const sites = useSelector((state) => state.sites.value);
	const [floor, setFloor] = useState(null);
	const [doors, setDoors] = useState([]);
	const [floors, setFloors] = useState([]);
	const loadingSite = useSelector((state) => state.sites.loading);
	const { loading } = useSelector((state) => state.notifications);
	const loadingDoors = useSelector((state) => state.doors.loading);
	const loadingFloors = useSelector((state) => state.floors.loading);
	const navigate = useNavigate();
	const { slug } = useParams();
	const isEdit = slug !== undefined;
	const { user } = useSelector((state) => state.auth);
	const [formData, setFormData] = useState({
		door: "",
	});
	const [errors, setErrors] = useState({});

	// const handleChange = (e) => {
	//   const { name, value } = e.target;
	//   setFormData((prevData) => ({ ...prevData, [name]: value }));
	// };

	const validateForm = () => {
		const newErrors = {};
		let valid = true;
		if (!startDate) {
			newErrors.startDate = "Start date is required";
			valid = false;
		}
		if (!endDate) {
			newErrors.endDate = "End date is required";
			valid = false;
		}
		if (siteIds.length == 0) {
			newErrors.site = "Site is required";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};

	const customStyles = {
		container: (provided) => ({
			...provided,
			width: "43%",
			color: "black",
		}),
	};
	const customStylesFloor = {
		container: (provided) => ({
			...provided,
			width: "43%",
			color: "black",
		}),
	};
	const customStylesDoor = {
		container: (provided) => ({
			...provided,
			width: "43%",
			color: "black",
		}),
	};

	// const handleFormSubmit = (event) => {
	//   event.preventDefault();
	// };

	const handleFormSubmit = (event) => {
		const date = new Date(endDate);
		event.preventDefault();
		if (validateForm()) {
			dispatch(
				getReport({
					data: {
						doorId: doorIds,
						siteId: siteIds,
						floorId: floorIds,
						from: dayjs(startDate).format("YYYY-MM-DD"),
						to: dayjs(endDate).format("YYYY-MM-DD"),
					},
				})
			).then((res) => {
				if (!res.error) {
					if (res.payload.length > 0) {
						generatePDF(res.payload);
					} else {
						toast.error("No Data availble to generate Report");
					}
				}
			});
		}
		// if (isEdit) {
		//   dispatch(updateSensor({ data: { id: slug, data: formData } })).then(
		//     (res) => {
		//       if (!res.error) {
		//         navigate("/sensors");
		//       }
		//     }
		//   );
		// } else {
		//   dispatch(addSensor({ data: formData })).then((res) => {
		//     console.log(res.payload);
		//     if (!res.error) {
		//       navigate("/sensors");
		//     }
		//   });
		// }
	};
	// const handleInputChange = (event) => {
	//   const { name, value } = event.target;
	//   setFormData((prevData) => ({
	//     ...prevData,
	//     [name]: value,
	//   }));
	// };
	useEffect(() => {
		if (user != null) {
			if (user.roles.id == process.env.REACT_APP_ADMIN_ROLE_ID) {
				dispatch(getSites());
			} else if (
				user.roles.id == process.env.REACT_APP_USER_ROLE_ID ||
				user.roles?.id == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
			) {
				dispatch(getSitesOfOrganization(user?.organizationId));
			}
		}
	}, [user]);

	useEffect(() => {
		if (siteIds.length > 0) {
			dispatch(getFloorsOfSite(siteIds[0])).then((res) => {
				setFloors(res.payload?.data);
			});
		}
	}, [siteIds]);
	useEffect(() => {
		if (floorIds.length > 0 && floorIds.length < 2) {
			dispatch(getDoorsofFloor(floorIds[0])).then((res) => {
				setDoors(res.payload.data);
			});
		}
	}, [floorIds]);

	useEffect(() => {
		document.title = "Report";
	}, []);

	const handleChange = (event) => {
		const ids = event.map((o) => o.data?.id);
		setSiteIds(ids);
	};
	const handleChangeFloor = (event) => {
		const ids = event.map((o) => o.data?.id);
		setFloorIds(ids);
	};

	const handleChangeDoor = (event) => {
		const ids = event.map((o) => o.data?.id);
		setDoorIds(ids);
	};
	const generatePDF = (json) => {
		// Dummy data for the PDF content
		const headers = Object.keys(json[0]).map((key) => {
			return key?.charAt(0)?.toUpperCase() + key?.slice(1);
		});

		// Modify keys
		const modifiedHeaders = headers?.map((header) => {
			if (header === "createdAt") return "Event Created On";
			return header;
		});
		json.forEach((row) => {
			row.Status =
				row.Status >= "200" && row.Status < "300"
					? "Conforming"
					: row.Status >= "300" && row.Status < 400
					? "Warning"
					: row.Status >= "400"
					? "Faulty"
					: "Conforming";
		});

		json.forEach((row) => {
			row.Time = `${row.Time.split("T")[0]} ${
				row.Time.split("T")[1].split(".")[0]
			}`;
		});

		const ws = xlsx.utils.json_to_sheet(json, { modifiedHeaders });
		const wb = xlsx.utils.book_new();
		xlsx.utils.book_append_sheet(wb, ws, "Data");
		xlsx.writeFile(wb, "Report.xlsx");
	};

	const formWrapperStyle = (element) => {
		let inner_width = window.innerWidth;
		if (inner_width > 480) {
			if (element === "wrapper") return { width: "43%" };
			if (element === "input") return { width: "50%" };
			if (element === "button") return { width: "43%" };
			if (element === "select") return customStyles;
		} else {
			if (element === "wrapper") return { width: "100%" };
			if (element === "input") return { width: "100%" };
			if (element === "button") return { width: "100%" };
			if (element === "select")
				return {
					container: (provided) => ({
						...provided,
						width: "100%",
						color: "black",
					}),
				};
		}
	};
	return (
		<Box className="flex justify-center items-center h-full flex-col mt-[10px]">
			<Paper className="w-1/2 h-[500px] gap-5 p-5 flex justify-center items-center flex-col !shadow-sm">
				<LocalizationProvider dateAdapter={AdapterDayjs}>
					<div style={formWrapperStyle("wrapper")}>
						<DatePicker
							sx={formWrapperStyle("input")}
							label="Start Date"
							value={startDate}
							onChange={(newValue) => setStartDate(newValue)}
							format="YYYY-MM-DD"
							renderInput={(params) => <TextField {...params} />}
							slotProps={{
								textField: {
									fullWidth: "100%",
									variant: "outlined",
									error: !!errors.startDate,
									helperText: errors?.startDate,
								},
							}}
						/>
						<DatePicker
							sx={formWrapperStyle("input")}
							label="End Date"
							value={endDate}
							format="YYYY-MM-DD"
							onChange={(newValue) => setEndDate(newValue)}
							renderInput={(params) => <TextField {...params} />}
							slotProps={{
								textField: {
									fullWidth: "100%",
									variant: "outlined",
									error: !!errors.endDate,
									helperText: errors?.endDate,
								},
							}}
						/>
					</div>
					{/* <Select
              label="Site"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <MenuItem value="Site 1">Site 1</MenuItem>
              <MenuItem value="Site 2">Site 2</MenuItem>
              <MenuItem value="Site 3">Site 3</MenuItem>

            </Select> */}
				</LocalizationProvider>

				<form
					className="flex items-center justify-center w-full"
					onSubmit={handleFormSubmit}>
					<Grid className="gap-5" container xs={12}>
						<Grid
							className="flex items-center justify-center"
							item
							xs={12}>
							<Select
								styles={formWrapperStyle("select")}
								isMulti
								getOptionValue={(option) => option.data?.id}
								getOptionLabel={(option) => option.data?.name}
								options={sites}
								onChange={handleChange}
								error={!!errors.site}
								helperText={errors.site}
							/>
						</Grid>
						<Grid
							className="flex items-center justify-center"
							item
							xs={12}>
							{siteIds.length > 0 && siteIds.length < 2 ? (
								<Select
									styles={formWrapperStyle("select")}
									isMulti
									getOptionValue={(option) => option.data?.id}
									getOptionLabel={(option) =>
										option.data?.description
									}
									options={floors}
									onChange={handleChangeFloor}
									error={!!errors.site}
									helperText={errors.site}
								/>
							) : null}
						</Grid>
						<Grid
							className="flex items-center justify-center"
							item
							xs={12}>
							{" "}
							{floorIds.length > 0 &&
							(floorIds.length < 2 || siteIds.length < 2) ? (
								<Select
									styles={formWrapperStyle("select")}
									isMulti
									getOptionValue={(option) => option.data?.id}
									getOptionLabel={(option) =>
										option.data?.description
									}
									options={doors}
									onChange={handleChangeDoor}
									error={!!errors.site}
									helperText={errors.site}
								/>
							) : null}
						</Grid>
						<Grid
							className="flex items-center justify-center"
							item
							xs={12}>
							<Button
								sx={formWrapperStyle("button")}
								className="customButtomOverAll"
								variant="contained"
								// onClick={generatePDF}
								type="submit">
								Generate Report
							</Button>
						</Grid>
					</Grid>
				</form>
			</Paper>
		</Box>
	);
};
// PDF styles

const styles = StyleSheet.create({
	page: {
		fontFamily: "Helvetica",
		padding: "2cm",
	},
	title: {
		fontSize: 20,
		marginBottom: "1rem",
	},
	date: {
		fontSize: 16,
		marginBottom: "0.5rem",
	},
	subtitle: {
		fontSize: 18,
		marginBottom: "0.5rem",
		textDecoration: "underline",
	},
	item: {
		fontSize: 14,
		marginBottom: "0.2rem",
	},
});
export default ReportGenerator;
