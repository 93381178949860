import React, { useState, useEffect, useRef } from "react";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import { v4 as uuidv4 } from "uuid";
import {
	getDoor,
	updateDoor,
	addDoor,
	uploadDoorImage,
} from "../../Store/Features/Door";
import "./Forms.css";
import Divider from "@mui/material/Divider";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import counterClockWise from "../../Assets/counterClockWise.png";
import clockWiseDoor from "../../Assets/clockWiseDoor.png";
import { useDispatch } from "react-redux";
import { API_URL } from "../../Utils/Functions/utils";

export const Confirm = ({ form, setForm, data }) => {
	const handleImage = (name, value) => {
		setForm((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleFileChange = (e) => {
		// Get the selected file
		const file = e.target.files[0];

		// Create a new FormData object
		const formData = new FormData();

		// Add the file to the FormData object
		formData.append("file", file);

		// Make API call to upload file
		// fetch('/api/upload', {
		//   method: 'POST',
		//   body: formData
		// })
		// .then(res => res.json())
		// .then(data => {
		//   // File uploaded successfully
		// })
		// .catch(err => {
		//   // Handle error
		// });
	};

	return (
		<div className="SensorConnection">
			<div className="Content w-full justify-start">
				<div className="firstHalfDoorInformation">
					<Text>Confirm Door Information</Text>

					<ImageCheckboxes
						form={form}
						setForm={setForm}
						name1="fit"
						name2="closed"
					/>
				</div>
				<Divider orientation="vertical" flexItem></Divider>

				<div className="SecondHalfDoorInformation">
					<div className="contentForm">
						<Headings>Details</Headings>
						<div className="SensorInfo gap-5 box-border">
							<ImageUploadButton
								onChange={handleImage}
								name={"door"}
								path={"doors/door_image"}
							/>
							<div className="ButtonsGroup-1">
								<Input
									form={form}
									setForm={setForm}
									name="rfidCode"
								/>
								<Input
									form={form}
									setForm={setForm}
									name="doorId"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const Input = ({ setForm, name, form }) => {
	return (
		<div className="SensorInputs">
			<input
				placeholder={`"${name}"`}
				className="inputSensors"
				value={form[name]}
				onChange={(e) =>
					setForm((prev) => ({ ...prev, [name]: e.target.value }))
				}
			/>
		</div>
	);
};

function ImageCheckboxes({ form, setForm, name1, name2 }) {
	const handleChange = (event) => {
		setForm({ ...form, [name1]: event.target.checked });
	};
	const handleChange1 = (event) => {
		setForm({ ...form, [name2]: event.target.checked });
	};

	return (
		<FormControl fullWidth>
			<FormGroup>
				<FormControlLabel
					control={
						<Checkbox
							checked={form["fit"] === true}
							onChange={handleChange}
							name="fit"
							value="Counter Clockwise"
						/>
					}
					label="Confirm fit & finish the door"
				/>

				<FormControlLabel
					control={
						<Checkbox
							checked={form["closed"] === true}
							onChange={handleChange1}
							name="close"
							value="Clockwise"
						/>
					}
					label="Confirm Door Closed"
				/>
			</FormGroup>
		</FormControl>
	);
}

const ImageUploadButton = ({ onChange, name, path, data }) => {
	const dispatch = useDispatch();

	const handleImageChange = (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path}/${filename}`;

		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		dispatch(uploadDoorImage(formData)).then((res) => {
			onChange("door_image", {
				id: id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		});
	};
};

function extractExtensionFrom(filename) {
	if (!filename) {
		return null;
	}

	const regex = /(?:\.([^.]+))?$/;
	return regex.exec(filename)[1];
}
