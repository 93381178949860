import * as React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import FilledInput from "@mui/material/FilledInput";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch } from "react-redux";
import { getSensorsbyCode } from "../../Store/Features/Sensors";
import { useNavigate, useParams } from "react-router-dom";
import { Headings } from "../../Shared/Fonts/Fonts";
import CircularProgress from "@mui/material/CircularProgress";

export default function Search() {
	const navigate = useNavigate();
	const [code, setCode] = useState("");
	const { assetId } = useParams();
	const [results, setResults] = useState([]);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setError("");
	}, [code]);

	const dispatch = useDispatch();

	const onSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		dispatch(getSensorsbyCode(code))
			.then((res) => {
				if (!res.error) {
					if (res.payload.count > 0) {
						const query =
							code.startsWith("hf") || code.startsWith("HF");
						setLoading(false);
						if (query) {
							navigate(
								`/sites/${res.payload.data[0].data.floor.siteId}/floor/${res.payload.data[0].data.floorId}/asset/${res.payload.data[0].data.id}`
							);
						} else {
							navigate(
								`/sites/${res.payload.rows[0].siteId}/floor/${res.payload.rows[0].floorId}/asset/${res.payload.rows[0].door.id}`
							);
						}
					} else {
						setError("No device found against this device code.");
						setLoading(false);
					}
				}
			})
			.catch((err) => setLoading(false));
	};

	return (
		<Box className="flex flex-wrap justify-center items-center h-full">
			<form onSubmit={onSubmit}>
				<Input
					id="filled-adornment-amount"
					placeholder="Search Device..."
					value={code}
					onChange={(e) => setCode(e.target.value)}
					className="bg-white py-2 px-[15px]"
					startAdornment={
						<InputAdornment position="start">
							<IconButton onClick={onSubmit}>
								<SearchIcon />
							</IconButton>
						</InputAdornment>
					}
					endAdornment={
						<InputAdornment position="end">
							{loading ? <CircularProgress size={25} /> : null}
						</InputAdornment>
					}
				/>
				<Headings className="text-xs text-red-600">{error}</Headings>
			</form>
		</Box>
	);
}
