import React, { useState, useEffect } from "react";
import { Button, Modal, TextField } from "@mui/material";
import "./Modal.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Headings } from "../../Shared/Fonts/Fonts";
import { addInspection } from "../../Store/Features/Inspection";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
const Inspection = ({ isOpen, setIsOpen, id, error }) => {
	const [startDate, setStartDate] = useState(dayjs());
	const dispatch = useDispatch();
	const [errors, setErrors] = useState({});
	const [formData, setFormData] = useState({
		inspection_date: dayjs(startDate).format("YYYY-MM-DD"),
		door: id,
	});
	// const { loading } = useSelector(state => state.inspections);

	const handleClose = () => {
		setIsOpen(false);
	};
	const AddInspections = () => {
		dispatch(
			addInspection({
				data: {
					inspection_date: dayjs(startDate).format("YYYY-MM-DD"),
					door: id,
				},
			})
		).then((res) => {
			if (!res.error) {
				setIsOpen(false);
			}
		});
	};

	return (
		<div>
			<Modal open={isOpen} onClose={handleClose} className="custom-modal">
				<div className="modal-content-door">
					<div className="Button_group_custom">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DatePicker
								className="w-full"
								label="Inspection Date"
								value={startDate}
								onChange={(newValue) => setStartDate(newValue)}
								format="YYYY-MM-DD"
								renderInput={(params) => (
									<TextField {...params} />
								)}
								slotProps={{
									textField: {
										fullWidth: "100%",
										variant: "outlined",
										error: !!errors.startDate,
										helperText: errors?.startDate,
									},
								}}
							/>

							{/* <Select
              label="Site"
              value={site}
              onChange={(e) => setSite(e.target.value)}
            >
              <MenuItem value="Site 1">Site 1</MenuItem>
              <MenuItem value="Site 2">Site 2</MenuItem>
              <MenuItem value="Site 3">Site 3</MenuItem>

            </Select> */}
						</LocalizationProvider>
					</div>
					<label htmlFor="image-button">
						<Button
							variant="outlined"
							component="span"
							fullWidth
							onClick={AddInspections}>
							Add Inspection
						</Button>
					</label>
					<Headings className="text-red-600">{error}</Headings>
				</div>
			</Modal>
		</div>
	);
};

export default Inspection;
