// import React, { useState } from "react";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import Button from "@mui/material/Button";

// const MarkerModal = ({ isOpen, onRequestClose, onSave }) => {
//   const [data, setData] = useState({
//     name: "",
//     type: "",
//     // Add more fields here as needed
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setData((prevData) => ({ ...prevData, [name]: value }));
//   };

//   const handleSave = () => {
//     onSave(data);
//     setData({ name: "", type: "" }); // Clear input fields after saving

//   };

//   return (
//     <Dialog open={isOpen} onClose={onRequestClose}>
//       <DialogTitle>Attach Data to Marker</DialogTitle>
//       <DialogContent>
//         <div>
//           <label>Name:</label>
//           <input type="text" name="name" value={data.name} onChange={handleChange} />
//         </div>
//         <div>
//           <label>Type:</label>
//           <input type="text" name="type" value={data.type} onChange={handleChange} />
//         </div>
//         {/* Add more fields here as needed */}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onRequestClose} color="primary">
//           Cancel
//         </Button>
//         <Button onClick={handleSave} color="primary">
//           Save
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default MarkerModal;

import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import { useParams } from "react-router-dom";
import { TextField, Button, Grid, MenuItem, Box } from "@mui/material";
import { Headings } from "../../Shared/Fonts/Fonts";
import { v4 as uuidv4 } from "uuid";
import {
	getDoor,
	updateDoor,
	addDoor,
	uploadDoorImage,
	uploadDoc,
} from "../../Store/Features/Door";
import { getSites } from "../../Store/Features/Sites";
import { useDispatch, useSelector } from "react-redux";
import { getFloorsOfSite } from "../../Store/Features/Floors";

import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import { API_URL } from "../../Utils/Functions/utils";
import useMediaQuery from "@mui/material/useMediaQuery";

const useStyles = makeStyles((theme) => ({
	dialog: {
		width: ((props) => (props.isMobile ? "100%" : 500)) || 500, // Adjust the width as needed
		margin: "auto", // Center the dialog horizontally
	},
	inputField: {
		marginBottom: theme.spacing(2),
	},
}));

const MarkerModal = ({ isOpen, onRequestClose, onSave, canvas }) => {
	const matches = useMediaQuery("(max-width:769px)");
	const classes = useStyles({ isMobile: matches });
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.doors.loading);
	const sites = useSelector((state) => state.sites.value);
	const doorsOnCanvas = canvas.getObjects();
	const [site, setSite] = useState(null);
	const [floors, setFloors] = useState([]);
	const [doors, setDoors] = useState([]);
	const navigate = useNavigate();
	const { floorId } = useParams();
	const initialData = {
		description: "",
		width: "",
		height: "",
		length: "",
		top_gap: "",
		bottom_gap: "",
		right_gap: "",
		left_gap: "",
		direction: "",
		floor: floorId,
	};

	const [formData, setFormData] = useState({
		description: "",
		width: "",
		height: "",
		length: "",
		top_gap: "",
		bottom_gap: "",
		right_gap: "",
		left_gap: "",
		direction: "",
		floor: floorId,
	});
	const [errors, setErrors] = useState({});

	const handleImage = (name, value) => {
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	const validateForm = () => {
		const newErrors = {};
		let valid = true;
		const regexPattern = /hf\d{7}/i;
		if (!formData.description.trim()) {
			newErrors.description = "Enter harmony Code for door";
			valid = false;
		}

		// if(checkDuplicate(formData.description)) {
		//   newErrors.description = "Door number already exists"
		//   valid = false
		// }

		if (!formData.description.trim().match(regexPattern)) {
			newErrors.description =
				"Please Enter Valid Harmony Code for Door,e.g. HFXXXXXXX";
			valid = false;
		}

		setErrors(newErrors);
		return valid;
	};
	console.log(doorsOnCanvas);

	const handleFormSubmit = (event) => {
		event.preventDefault();
		if (!validateForm()) {
			return;
		}

		dispatch(addDoor({ data: formData })).then((res) => {
			if (!res.error) {
				setFormData(initialData);
				onSave(res.payload);
			}
		});
	};

	const handleDoc = (name, value) => {
		setFormData((prevData) => ({ ...prevData, [name]: value }));
	};

	// const handleInputChange = (event) => {
	//   const { name, value } = event.target;
	//   setFormData((prevData) => ({
	//     ...prevData,
	//     [name]: value,
	//   }));
	// };

	return (
		<Dialog
			open={isOpen}
			onClose={onRequestClose}
			className={classes.dialog}>
			<DialogTitle>Add harmony code of Door</DialogTitle>
			<DialogContent>
				<form onSubmit={handleFormSubmit}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								label="Harmony code"
								variant="filled"
								fullWidth
								name="description"
								value={formData.description}
								onChange={handleChange}
								error={!!errors.description}
								helperText={errors.description}
							/>
						</Grid>
						<Grid item xs={12}>
							<Box className="flex flex-col justify-center w-full gap-[1px]">
								<ImageUploadButton
									onChange={handleDoc}
									path1={"doors/installation_certificate"}
									title={"Install Certitificate"}
								/>
								<ImageUploadButton1
									onChange={handleDoc}
									path1={"doors/manufacturer_certificate"}
									title={"Manufacture Certitificate"}
								/>
								<ImageUploadButton2
									onChange={handleDoc}
									path1={"doors/maintaince_records"}
									title={"Maintainance Records"}
								/>
							</Box>
						</Grid>
						{/* <Grid item xs={6}>
            <TextField
              label="Email"
              variant="filled"
              fullWidth
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone Number"
              variant="filled"
              fullWidth
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
          </Grid>*/}

						<Grid item xs={12}>
							<Button
								type="submit"
								variant="contained"
								color="primary">
								{loading ? (
									<CircularProgress color="inherit" />
								) : (
									"Create"
								)}
							</Button>
						</Grid>
					</Grid>
				</form>
				{/* Add more fields here as needed */}
			</DialogContent>
			<DialogActions></DialogActions>
		</Dialog>
	);
};

export default MarkerModal;
const ImageUploadButton = ({ onChange, path1, title }) => {
	const dispatch = useDispatch();

	const handleImageChange = (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile?.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path1}/${filename}`;
		const uri = `/file/upload/${path1}`;
		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		dispatch(uploadDoc({ path: path1, data: formData })).then((res) => {
			onChange(path1.split("/")[1], {
				id: id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		});
	};

	// const handleUpload = () => {
	//   // You can implement the image upload logic here
	//   if (selectedImage) {
	//     // Example: Send the selectedImage to your backend for processing
	//     console.log('Uploading image:', selectedImage.name);
	//   }
	// };

	return (
		<div>
			<input
				accept=".xlsx,.xls,.doc, .docx,.ppt,.pptx,.pdf"
				className="hidden"
				id="image-upload-input"
				type="file"
				onChange={handleImageChange}
			/>
			<label htmlFor="image-upload-input">
				<Button
					type="button"
					className="button customButtomOverAll buttonCustomModal capitalize"
					variant="contained"
					component="span">
					<Headings className="capitalize text-wrap">
						{title}
					</Headings>
				</Button>
			</label>
		</div>
	);
};
const ImageUploadButton1 = ({ onChange, name, path1, title }) => {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const handleImageChange = (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile?.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path1}/${filename}`;
		const uri = `/file/upload/${path1}`;
		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		dispatch(uploadDoc({ path: path1, data: formData })).then((res) => {
			onChange(path1.split("/")[1], {
				id: id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		});
	};

	// const handleUpload = () => {
	//   // You can implement the image upload logic here
	//   if (selectedImage) {
	//     // Example: Send the selectedImage to your backend for processing
	//     console.log('Uploading image:', selectedImage.name);
	//   }
	// };

	return (
		<div>
			<input
				accept=".xlsx,.xls,.doc, .docx,.ppt,.pptx,.pdf"
				className="hidden"
				id="image-upload-input1"
				type="file"
				onChange={handleImageChange}
			/>
			<label htmlFor="image-upload-input1">
				<Button
					type="button"
					className="button customButtomOverAll buttonCustomModal capitalize"
					variant="contained"
					component="span">
					<Headings className="capitalize text-wrap">
						{title}
					</Headings>
				</Button>
			</label>
		</div>
	);
};
const ImageUploadButton2 = ({ onChange, name, path1, title }) => {
	const dispatch = useDispatch();

	const handleImageChange = (event) => {
		const selectedFile = event.target.files[0];
		const extension = extractExtensionFrom(selectedFile?.name);
		const id = uuidv4();
		const filename = `${id}.${extension}`;
		const privateUrl = `${path1}/${filename}`;
		const uri = `/file/upload/${path1}`;
		const publicUrl = API_URL() + `file/download?privateUrl=${privateUrl}`;
		const formData = new FormData();
		formData.append("file", selectedFile);
		formData.append("filename", filename);

		dispatch(uploadDoc({ path: path1, data: formData })).then((res) => {
			onChange(path1.split("/")[1], {
				id: id,
				name: selectedFile.name,
				sizeInBytes: selectedFile.size,
				privateUrl,
				publicUrl,
				new: true,
			});
		});
	};

	// const handleUpload = () => {
	//   // You can implement the image upload logic here
	//   if (selectedImage) {
	//     // Example: Send the selectedImage to your backend for processing
	//     console.log('Uploading image:', selectedImage.name);
	//   }
	// };

	return (
		<div>
			<input
				accept=".xlsx,.xls,.doc, .docx,.ppt,.pptx,.pdf"
				className="hidden"
				id="image-upload-input2"
				type="file"
				onChange={handleImageChange}
			/>
			<label htmlFor="image-upload-input2">
				<Button
					type="button"
					className="button customButtomOverAll buttonCustomModal capitalize"
					variant="contained"
					component="span">
					<Headings className="capitalize text-wrap">
						{title}
					</Headings>
				</Button>
			</label>
		</div>
	);
};

function extractExtensionFrom(filename) {
	if (!filename) {
		return null;
	}

	const regex = /(?:\.([^.]+))?$/;
	return regex.exec(filename)[1];
}
