import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getUsers,
	deleteUser,
	updateUser,
	getOrganizationUsers,
} from "../../Store/Features/Users";
import { Headings } from "../../Shared/Fonts/Fonts";
import { DataGrid } from "@mui/x-data-grid";
import { Button, Menu, MenuItem, IconButton, Switch } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "../../Assets/Edit.svg";
import search from "../../Assets/search.svg";
import "./Users.css";

const Users = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		value: users,
		totalItems,
	} = useSelector((state) => state.users);
	const { user } = useSelector((state) => state.auth);
	const [searchQuery, setSearchQuery] = useState("");
	const { organizationId } = useParams();
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});

	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setPaginationModel({ ...paginationModel, page: 0 });
	};

	const handleDeleteUser = (id) => {
		dispatch(deleteUser(id)).then(() => {
			fetchUsers();
		});
	};

	const fetchUsers = () => {
		const params = {
			page: paginationModel.page + 1,
			limit: paginationModel.pageSize,
			search: searchQuery,
			sort: sortModel[0].sort,
			field: sortModel[0].field,
		};
		if (
			organizationId ||
			user?.roleId === process.env.REACT_APP_ORG_ADMIN_ROLE_ID
		) {
			dispatch(
				getOrganizationUsers({
					...params,
					organizationId: organizationId || user.organizationId,
				})
			);
		} else {
			dispatch(getUsers(params));
		}
	};

	useEffect(() => {
		document.title = "Users";
	}, []);

	useEffect(() => {
		if (sortModel && sortModel[0].sort) {
			fetchUsers();
		}
	}, [
		dispatch,
		organizationId,
		user,
		paginationModel,
		sortModel,
		searchQuery,
	]);

	const columns = [
		{
			field: "firstName",
			headerName: "Name",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal">
					{`${params.row.firstName} ${params.row.lastName || ""}`}
				</Headings>
			),
		},
		{
			field: "organization",
			headerName: "Organization",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal">
					{params.row.organizationId
						? params.row.organization?.name
						: "Non-Organization-User"}
				</Headings>
			),
		},
		{ field: "phoneNumber", headerName: "Phone Number", flex: 1 },
		{
			field: "email",
			headerName: "Email",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal">
					{params.row.email}
				</Headings>
			),
		},
		{
			field: "role",
			headerName: "Role",
			flex: 1,
			renderCell: (params) => (
				<Headings className="text-base text-[#212B36] font-futura-book font-normal">
					{params.row.roles?.title}
				</Headings>
			),
		},
		{
			field: "disabled",
			headerName: "Disabled",
			flex: 1,
			renderCell: (params) => (
				<Switch
					checked={params.row.disabled}
					onChange={(e) => {
						const { id } = params.row;
						dispatch(
							updateUser({
								id,
								data: { disabled: e.target.checked },
							})
						).then(() => {
							fetchUsers();
						});
					}}
				/>
			),
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 1,
			sortable: false,
			renderCell: (params) => (
				<ActionsDropdown
					userId={params.row.id}
					onDelete={handleDeleteUser}
				/>
			),
		},
	];

	return (
		<div className="OverAllSites">
			<div className="searchspace">
				<div className="Heading">
					<h1 className="text-xl font-medium">Users</h1>
					<button
						className="addSiteButton customButtomOverAll"
						onClick={() =>
							organizationId ||
							user?.roleId ===
								process.env.REACT_APP_ORG_ADMIN_ROLE_ID
								? navigate(
										`/organizations/${
											organizationId ||
											user.organizationId
										}/users/add`
								  )
								: navigate("/users/add")
						}>
						<Headings>Add User</Headings>
					</button>
					{user?.roleId === process.env.REACT_APP_ADMIN_ROLE_ID &&
						!organizationId && (
							<button
								className="addSiteButton customButtomOverAll"
								onClick={() => navigate("/organizations")}>
								<Headings>Organisations</Headings>
							</button>
						)}
				</div>
				<div className="inputdiv">
					<img src={search} alt="Search" className="searchimage" />
					<input
						className="searchinput"
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>
			<div className="w-full">
				<DataGrid
					rows={users?.data || []}
					columns={columns}
					rowCount={users?.totalItems || 0}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					sortModel={sortModel}
					onSortModelChange={(model) =>
						model.length > 0 ? setSortModel(model) : null
					}
					className="py-[10px] px-[30px] w-full bg-white !border-none !rounded-none shadow-sm"
					sx={{
						"& .MuiDataGrid-iconButtonContainer": {
							visibility: "visible",
						},
						"& .MuiDataGrid-sortIcon": {
							opacity: "inherit !important",
						},
						"& .MuiDataGrid-cell": {
							display: "flex",
							alignItems: "center",
							padding: "0",
							color: "#212B36",
							fontFamily: "Futura PT Book !important",
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							fontFamily: "Futura PT",
							fontWeight: "500",
							fontSize: 17,
							padding: "0",
						},
						"& .MuiTablePagination-select": {
							background: "#EEE",
						},
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& > .MuiDataGrid-columnSeparator": {
							display: "none !important",
						},
					}}
					disableColumnMenu
					disableColumnSelector
				/>
			</div>
		</div>
	);
};

const ActionsDropdown = ({ userId, onDelete }) => {
	const { organizationId } = useParams();
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const { user } = useSelector((state) => state.auth);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleDeleteClick = () => {
		onDelete(userId);
		handleClose();
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick}>
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem
					onClick={() =>
						organizationId ||
						user?.roleId == process.env.REACT_APP_ORG_ADMIN_ROLE_ID
							? navigate(
									`/organizations/${
										user?.roleId ==
										process.env.REACT_APP_ORG_ADMIN_ROLE_ID
											? user?.organizationId
											: organizationId
									}/users/${userId}`
							  )
							: navigate(`/users/edit/${userId}`)
					}>
					<EditIcon fontSize="small" />
					Edit
				</MenuItem>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
			</Menu>
		</div>
	);
};
export default Users;
