import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	getSites,
	getSitesOfOrganization,
	deleteSite,
} from "../../Store/Features/Sites";
import { Headings } from "../../Shared/Fonts/Fonts";
import { DataGrid } from "@mui/x-data-grid";
import { IconButton, Menu, MenuItem } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import search from "../../Assets/search.svg";
import Edit from "../../Assets/Edit.svg";
import { getStatusMessage } from "../../Utils/Functions/getStatusMessages";
import "./Sites.css";

export const Sites = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		loading,
		count,
		value: sitesData,
	} = useSelector((state) => state.sites);
	const { user } = useSelector((state) => state.auth);
	const [searchQuery, setSearchQuery] = useState("");
	const matches = useMediaQuery("(max-width:769px)");
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState([
		{ field: "createdAt", sort: "desc" },
	]);

	console.log("sitesData", sitesData);

	const sites =
		sitesData && Array.isArray(sitesData)
			? sitesData.map((site) => ({
					id: site.data.id,
					name: site.data.name,
					...site.data,
					...site,
			  }))
			: [];

	console.log("sites", sites);

	const handleSearchChange = (e) => {
		setSearchQuery(e.target.value);
		setPaginationModel({ ...paginationModel, page: 0 });
	};

	useEffect(() => {
		if (user != null) {
			const action = user?.organizationId
				? getSitesOfOrganization
				: getSites;
			const params = {
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				sort: sortModel[0].sort,
				field: sortModel[0].field,
				search: searchQuery,
			};

			if (user?.organizationId) {
				params.organization = user.organizationId;
			}

			dispatch(action(params));
		}
	}, [dispatch, user, paginationModel, sortModel, searchQuery]);

	useEffect(() => {
		document.title = "Sites";
	}, []);

	const handleDelete = (id) => {
		dispatch(deleteSite(id)).then(() => {
			const action = user?.organizationId
				? getSitesOfOrganization
				: getSites;
			const params = {
				page: paginationModel.page + 1,
				limit: paginationModel.pageSize,
				sort: sortModel[0].sort,
				field: sortModel[0].field,
				search: searchQuery,
			};

			if (user?.organizationId) {
				params.organization = user.organizationId;
			}

			dispatch(action(params));
		});
	};

	const columns = [
		{
			field: "name",
			headerName: "Site Name",
			flex: 1,
		},
		{
			field: "location",
			headerName: "Location",
			flex: 1,
		},
		{
			field: "sector",
			headerName: "Building Type",
			flex: 1,
		},
		{
			field: "sensorsCount",
			headerName: "No of Devices",
			flex: 1,
		},
		{
			field: "floorsCount",
			headerName: "No of Floors",
			flex: 1,
		},
		{
			field: "doorsCount",
			headerName: "No of Doors",
			flex: 1,
		},
		{
			field: "status",
			headerName: "Site Status",
			flex: 1,
			renderCell: (params) => {
				const { color, background } = getStatus(params.row.status);
				return (
					<div
						className="flex justify-center items-center h-8 py-2 w-full"
						style={{
							backgroundColor: background,
						}}>
						<Headings
							color={color}
							className="text-base text-[#212B36] font-futura font-extralight capitalize">
							{getStatusMessage(params.row.status)}
						</Headings>
					</div>
				);
			},
		},
		{
			field: "actions",
			headerName: "Actions",
			flex: 0.5,
			sortable: false,
			renderCell: (params) => (
				<ActionsDropdown
					siteId={params.row.id}
					onDelete={handleDelete}
				/>
			),
		},
	];

	return (
		<div className="OverAllSites" fixed>
			<div className="searchspace">
				<div className="Heading">
					<h1 className="text-xl font-medium">Sites</h1>
					{(user?.roleId == process.env.REACT_APP_ADMIN_ROLE_ID ||
						user?.roleId ==
							process.env.REACT_APP_ORG_ADMIN_ROLE_ID) && (
						<button
							className="addSiteButton customButtomOverAll"
							onClick={() => navigate("/sites/addSite")}>
							<Headings>Add Site</Headings>
						</button>
					)}
				</div>
				<div className="inputdiv">
					<img src={search} alt="No search" className="searchimage" />
					<input
						className="searchinput"
						type="text"
						placeholder="Search..."
						value={searchQuery}
						onChange={handleSearchChange}
					/>
				</div>
			</div>

			<div className="w-full">
				<DataGrid
					rows={sites}
					columns={columns}
					rowCount={count || 0}
					loading={loading}
					pageSizeOptions={[5, 10, 20, 25, 50, 100]}
					paginationModel={paginationModel}
					paginationMode="server"
					onPaginationModelChange={setPaginationModel}
					sortModel={sortModel}
					onSortModelChange={(model) =>
						model.length > 0 ? setSortModel(model) : null
					}
					getRowId={(row) => row.id}
					onRowClick={(params) => navigate(`/sites/${params.row.id}`)}
					columnVisibilityModel={{
						actions:
							user.roles?.id ===
							process.env.REACT_APP_ADMIN_ROLE_ID,
					}}
					className="py-[10px] px-[30px] w-full bg-white !border-none !rounded-none shadow-sm"
					sx={{
						"& .MuiDataGrid-iconButtonContainer": {
							visibility: "visible",
						},
						"& .MuiDataGrid-sortIcon": {
							opacity: "inherit !important",
						},
						"& .MuiDataGrid-cell": {
							display: "flex",
							alignItems: "center",
							padding: "0",
							color: "#212B36",
							fontFamily: "Futura PT Book !important",
							cursor: "pointer",
						},
						"& .MuiDataGrid-columnHeaders": {
							fontFamily: "Futura PT",
							fontWeight: "500",
							fontSize: 17,
							padding: "0",
						},
						"& .MuiTablePagination-select": {
							background: "#EEE",
						},
						"&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
							outline: "none !important",
						},
						"& > .MuiDataGrid-columnSeparator": {
							display: "none !important",
						},
					}}
					disableColumnMenu
					disableColumnSelector
				/>
			</div>
		</div>
	);
};

const ActionsDropdown = ({ siteId, onDelete }) => {
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleView = () => {
		navigate(`/sites/${siteId}`);
	};

	const handleDeleteClick = () => {
		onDelete(siteId);
		setAnchorEl(false);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<IconButton variant="outlined" onClick={handleClick}>
				<img src={Edit} alt="Edit" />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}>
				<MenuItem onClick={handleDeleteClick}>
					<DeleteIcon fontSize="small" />
					Delete
				</MenuItem>
				<MenuItem onClick={() => navigate(`/sites/edit/${siteId}`)}>
					<EditIcon fontSize="small" />
					Edit Site
				</MenuItem>
			</Menu>
		</div>
	);
};

const getStatus = (stat) => {
	let status = parseInt(stat);
	if (status >= 200 && status < 300) {
		return {
			color: "#34A853",
			background: "rgba(52, 168, 83, 0.1)",
		};
	} else if (status >= 300 && status < 400) {
		return {
			color: "rgba(221, 173, 1, 1)",
			background: "rgba(221, 173, 1, 0.1)",
		};
	} else if (status >= 400 && status < 500) {
		return {
			color: "#EB4335",
			background: "rgba(235, 67, 53, 0.1)",
		};
	} else {
		return {
			color: "#FFA500",
			background: "rgba(254, 216, 177, 0.1)",
		};
	}
};
