import React, { useState, useEffect } from "react";
import { Headings, SubHeadings, Text } from "../../Shared/Fonts/Fonts";
import "./Forms.css";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useParams } from "react-router-dom";
import { getSensorsbyDoor } from "../../Store/Features/Sensors";
import { useDispatch } from "react-redux";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
export const SensorConnection = ({ form, setForm }) => {
	const dispatch = useDispatch();
	const { assetId } = useParams();
	const [sensors, setSensors] = useState([]);
	const handleChange = (e) => {
		const { name, value } = e.target;
		const { sensor_code, id } = sensors[value];

		setForm((prev) => ({
			...prev,
			[name]: sensor_code,
			sensorId: id,
			sensor: sensors[value],
		}));
	};

	useEffect(() => {
		dispatch(getSensorsbyDoor(assetId)).then((res) => {
			setSensors(res.payload.rows);
		});
	}, []);

	return (
		<div className="SensorConnection">
			<div className="Content">
				<div className="SensorInfo">
					<FormControl>
						<InputLabel id="sensor-label">Sensor</InputLabel>
						<Select
							label="Sensor"
							labelId="sensor-label"
							variant="filled"
							value={form.site}
							onChange={handleChange}
							name="sensor_code"
							className="min-w-[300px] w-1/2"
							placeholder="Select an sensor">
							{sensors?.map((o, index) => (
								<MenuItem key={index} value={index}>
									{o.sensor_code}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</div>
			</div>
		</div>
	);
};
