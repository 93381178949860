import React, { useState, useEffect } from "react";
import authLogo from "../../Assets/authLogo.svg";
import {
	Card,
	CardContent,
	TextField,
	Button,
	InputAdornment,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import IconButton from "@mui/material/IconButton";
import "./Auth.css";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { resetEmail } from "../../Store/Features/Auth";
import { Headings, Text } from "../../Shared/Fonts/Fonts";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

export function ResetEmail() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState("");
	const [showPassword, setShowPassword] = React.useState(false);
	const [passwordError, setPasswordError] = useState("");
	const loading = useSelector((state) => state.auth.loading);

	const handleLogin = () => {
		// Validate fields before performing login action
		if (!email) {
			setEmailError("Email is required");
			return;
		}
		if (!isValidEmail(email)) {
			setEmailError("Invalid email format");
			return;
		}

		dispatch(resetEmail(email)).then((res) => {
			if (!res.error) {
				navigate("/login");
			}
		});
	};

	useEffect(() => {
		document.title = "Reset Password";
	}, []);

	const isValidEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	return (
		<Card className="login-card">
			<CardContent className="login-container">
				<div className="logo-container flex justify-center">
					<img src={authLogo} alt="Logo" />
				</div>
				<div className="HeadingText">
					<Headings className="text-lg">
						Welcome to Harmony Fire
					</Headings>
				</div>
				<TextField
					label="Email Address"
					placeholder="Enter your email address"
					fullWidth
					className="input-field"
					variant="standard"
					value={email}
					onChange={(e) => {
						setEmail(e.target.value);
						setEmailError("");
					}}
					error={!!emailError}
					helperText={emailError}
				/>
				<Button
					variant="contained"
					color="primary"
					className="login-button customButtomOverAll capitalize py-[13px] px-[22px]"
					onClick={handleLogin}>
					{loading ? (
						<CircularProgress color="inherit" />
					) : (
						<Headings className="text-white font-bold text-[15px]">
							Send Reset Link
						</Headings>
					)}
				</Button>
			</CardContent>
		</Card>
	);
}
